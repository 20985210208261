import { stateClient } from "./UpdateClient";
import { required } from "../../utils";

export type Error = {
  activities: string;
  socialReson: string;
  matriculation: string;
  tva: string;
  postalCode: string;
  address: string;
  countryCodeCompany: string;
  phoneCompany: string;
  firstName: string;
  lastName: string;
  countryCodePers: string;
  phonePers: string;
  activationDate: string;
};

export const Validation = (values: stateClient): Error => {
  let errors: Error = {
    activities: "",
    socialReson: "",
    matriculation: "",
    tva: "",
    postalCode: "",
    address: "",
    countryCodeCompany: "",
    phoneCompany: "",
    firstName: "",
    lastName: "",
    countryCodePers: "",
    phonePers: "",
    activationDate: "",
  };

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "company":
        if (!values.company?.activities) {
          errors.activities = required;
        } else if (values.company?.activities.length === 0) {
          errors.activities = required;
        } else {
          delete errors.activities;
        }
        if (!values.company?.fiscal?.corporateNames?.[0]?.value) {
          errors.socialReson = required;
        } else {
          delete errors.socialReson;
        }
        if (!values.company?.fiscal?.matriculation) {
          errors.matriculation = required;
        } else {
          delete errors.matriculation;
        }
        if (!values.company?.fiscal?.taxCode) {
          errors.tva = required;
        } else {
          delete errors.tva;
        }
        if (!values.company?.address?.postalCode) {
          errors.postalCode = required;
        } else {
          delete errors.postalCode;
        }
        if (!values.company?.address?.roadNames?.[0]?.value) {
          errors.address = required;
        } else {
          delete errors.address;
        }
        if (!values.company?.contacts?.[0]?.phone?.countryCode) {
          errors.countryCodeCompany = required;
        } else {
          delete errors.countryCodeCompany;
        }
        if (!values.company?.contacts?.[0]?.phone?.number) {
          errors.phoneCompany = required;
        } else {
          delete errors.phoneCompany;
        }
        break;
      case "informations":
        if (!values.informations?.firstName) {
          errors.firstName = required;
        } else {
          delete errors.firstName;
        }

        if (!values.informations?.lastName) {
          errors.lastName = required;
        } else {
          delete errors.lastName;
        }
        break;
      case "contact":
        if (!values.contact?.phone?.countryCode) {
          errors.countryCodePers = required;
        } else {
          delete errors.countryCodePers;
        }
        if (!values.contact?.phone?.number) {
          errors.phonePers = required;
        } else {
          delete errors.phonePers;
        }
        break;
      case "accountActivation":
        if (!values.accountActivation?.activationDate) {
          errors.activationDate = required;
        } else {
          delete errors.activationDate;
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
