import React, { createRef } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlus,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as _ from "lodash";
import {
  Button,
  Fab,
  Checkbox,
  ListItemText,
  MenuItem,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useIntl } from "react-intl";
//import local
import { getLanguage, getSocialIcon, getURLSocialMedia } from "../../utils";
import { WrapperStyles, NoteStyles, SeperatorStyles } from "./styles";
import { Box, SelectInput, Input, DatePicker } from "../../commons";
import { Validation, Error } from "./Validation";
//import apollo
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GETLISTCOMPANYACTIVITIES,
  GETLISTCOUNTRIES,
  GETLISTCITIES,
  GETLISTLANGUAGES,
  GETLISTCURRENCIES,
  GETLISTSOCIALKINDS,
  GETLISTUSERKIND,
  GETLISTACTIVATIONSTATUS,
  UPDATEUSER,
  GETLISTUSERS,
  GETMYACCOUNTSETTINGS,
} from "../../api";

import {
  GetListCompanyActivitiesQuery,
  GetListCompanyActivitiesQueryVariables,
  GetListCountriesQuery,
  GetListCountriesQueryVariables,
  GetListCitiesQuery,
  GetListCitiesQueryVariables,
  GetListLanguagesQuery,
  GetListCurrenciesQuery,
  GetListSocialKindsQuery,
  GetListSocialKindsQueryVariables,
  GetListUSerKindQuery,
  GetListUSerKindQueryVariables,
  GetListActivationStatusQuery,
  GetListActivationStatusQueryVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserPayload,
  AccountActivationInput,
  UserInformationsInput,
  UserContactInput,
  AccountSettingsInput,
  UpdateCompanyInput,
  CompanyActivityInput,
  CompanyCharacteristicInput,
  CompanyCharacteristicKind,
  CompanySocialInput,
  LanguageCode,
  CurrencyCode,
  GetMyAccountSettingsQuery,
  GetMyAccountSettingsQueryVariables,
} from "../../api/types";

export type stateClient = {
  email: string;
  kind: string;
  accountActivation?: AccountActivationInput;
  informations?: UserInformationsInput;
  contact?: UserContactInput;
  accountSettings?: AccountSettingsInput;
  company?: UpdateCompanyInput;
};

const initialStateClient: stateClient = {
  email: "",
  kind: "",
  accountActivation: undefined,
  informations: undefined,
  contact: undefined,
  accountSettings: undefined,
  company: undefined,
};

const initialError: Error = {
  activities: "",
  socialReson: "",
  matriculation: "",
  tva: "",
  postalCode: "",
  address: "",
  countryCodeCompany: "",
  phoneCompany: "",
  firstName: "",
  lastName: "",
  countryCodePers: "",
  phonePers: "",
  activationDate: "",
};

type Check = {
  alphanumeric: undefined | boolean;
  specialCaratcters: undefined | boolean;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const UpdateClient: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation<UserPayload>();
  const dataClient = location.state;

  const companyInformation = createRef<HTMLDivElement>();
  const personalInformation = createRef<HTMLDivElement>();
  const generalSetting = createRef<HTMLDivElement>();
  const account = createRef<HTMLDivElement>();
  const packes = createRef<HTMLDivElement>();

  const [languageCode, setLanguageCode] = React.useState<LanguageCode>("EN");

  //defintion state
  const [activities, setActivities] = React.useState<string[]>([]);
  const [selectedNet, setselectedNet] = React.useState<CompanySocialInput>({
    kind: undefined,
    value: undefined,
  });
  const [formClient, setFormClient] = React.useState<stateClient>(
    initialStateClient
  );
  const [errorForm, setErrorForm] = React.useState<Error>(initialError);

  //for Snackbar
  const [open, setOpen] = React.useState(false);
  const [snackbarMessages, setSnackbarMessages] = React.useState("");

  const [nameError, setNameError] = React.useState<Check>({
    alphanumeric: undefined,
    specialCaratcters: undefined,
  });

  //Query Get My Account settings
  const { data: accountSettings } = useQuery<
    GetMyAccountSettingsQuery,
    GetMyAccountSettingsQueryVariables
  >(GETMYACCOUNTSETTINGS, {
    variables: {
      token: token,
    },
  });

  React.useEffect(() => {
    setLanguageCode(
      accountSettings?.getMyAccountSettings?.accountSettings
        ?.language as LanguageCode
    );
  }, [accountSettings]);

  //Query GETLISTCOMPANYACTIVITIES
  const { data: Activities } = useQuery<
    GetListCompanyActivitiesQuery,
    GetListCompanyActivitiesQueryVariables
  >(GETLISTCOMPANYACTIVITIES, {
    variables: { input: { languageCode: languageCode } },
  });

  //Query GETLISTCOUNTRIES
  const { data: listCountry } = useQuery<
    GetListCountriesQuery,
    GetListCountriesQueryVariables
  >(GETLISTCOUNTRIES);

  //Query GETLISTCITIES
  const { data: listCities } = useQuery<
    GetListCitiesQuery,
    GetListCitiesQueryVariables
  >(GETLISTCITIES, {
    variables: {
      countryId: formClient?.company?.address?.country || "",
    },
    skip: !formClient?.company?.address?.country,
  });
  //Query GETLISTLANGUAGES
  const { data: language } = useQuery<GetListLanguagesQuery>(GETLISTLANGUAGES);

  //Query GETLISTCURRENCIES
  const { data: Currencies } = useQuery<GetListCurrenciesQuery>(
    GETLISTCURRENCIES
  );

  //queries GETLISTUSERKIND
  const { data: kind } = useQuery<
    GetListUSerKindQuery,
    GetListUSerKindQueryVariables
  >(GETLISTUSERKIND, { variables: { input: { languageCode: "EN" } } });

  //Query GETLISTSOCIALKINDS
  const { data: social } = useQuery<
    GetListSocialKindsQuery,
    GetListSocialKindsQueryVariables
  >(GETLISTSOCIALKINDS, { variables: { input: { languageCode: "EN" } } });

  //Query GETLISTACTIVATIONSTATUS
  const { data: activationStatus } = useQuery<
    GetListActivationStatusQuery,
    GetListActivationStatusQueryVariables
  >(GETLISTACTIVATIONSTATUS, {
    variables: {
      input: { languageCode: "EN" },
    },
  });

  //Mutation UPDATEUSER

  const [UpdateUser, { loading }] = useMutation<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >(UPDATEUSER, {
    onCompleted: () => {
      history.push("/");
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.message === "domain_already_exists") {
        setSnackbarMessages("domain_already_exists");
        setOpen(true);
      }
    },
    refetchQueries: () => [
      {
        query: GETLISTUSERS,
        variables: {
          input: {
            token: token,
            languageCode: "EN",
            kind: "MANAGER",
            pageSize : 10,
            pageNumber :1
          },
        },
      },
    ],
  });

  //fonctions
  const getActivities = (list: string[]) => {
    let name: string[] = [];
    list.map((activity) => {
      let actName = Activities?.getListCompanyActivities.list.filter(
        (act) => act.id === activity
      );
      return (
        actName && actName.length > 0 && name.push(actName?.[0].name || "")
      );
    });
    return name;
  };

  React.useEffect(() => {
    const arrayActivites: string[] = [];
    let listActivities: CompanyActivityInput[] = [];
    const arrayCharacteristics: CompanyCharacteristicInput[] = [];
    const arraySocials: CompanySocialInput[] = [];

    dataClient?.company?.activities?.map(
      (item) => item.activity?.id && arrayActivites.push(item.activity.id)
    );
    setActivities(arrayActivites);

    dataClient?.company?.activities?.map(
      (item) =>
        item.activity?.id && listActivities.push({ activity: item.activity.id })
    );

    dataClient?.company?.characteristics?.map((item) =>
      arrayCharacteristics.push({
        kind: item.kind as CompanyCharacteristicKind,
        value: item.value,
      })
    );

    dataClient?.company?.socials?.map((item) =>
      arraySocials.push({ kind: item.kind?.id, value: item.value })
    );

    setFormClient({
      email: dataClient.email || "",
      kind: dataClient.kind?.id || "",
      accountActivation: {
        activationDate: dataClient.accountActivation?.activationDate,
        activationStatus: dataClient.accountActivation?.activationStatus?.id,
      },
      informations: {
        firstName: dataClient.informations?.firstName,
        lastName: dataClient.informations?.lastName,
        birthday: dataClient.informations?.birthday,
        gender: dataClient.informations?.gender?.id,
        civilStatus: dataClient.informations?.civilStatus?.id,
        picture: dataClient.informations?.picture?.id,
      },
      contact: {
        phone: {
          countryCode: dataClient.contact?.phone?.countryCode,
          number: dataClient.contact?.phone?.number,
        },
        socials: [],
      },
      company: {
        id: dataClient.company?.id,
        // domain: dataClient.company?.domain,
        activities: listActivities,
        contacts: [
          {
            phone: {
              countryCode:
                dataClient.company?.contacts?.[0]?.phone?.countryCode,
              number: dataClient.company?.contacts?.[0]?.phone?.number,
            },
          },
        ],
        address: {
          id: dataClient.company?.address?.id,
          city: dataClient.company?.address?.city?.id,
          country: dataClient.company?.address?.country?.id,
          roadNames: [
            {
              id: dataClient.company?.address?.roadNames?.[0]?.id,
              value: dataClient.company?.address?.roadNames?.[0]?.value,
              languageCode: "EN",
            },
          ],
          postalCode: dataClient.company?.address?.postalCode,
        },
        characteristics: arrayCharacteristics,
        fiscal: {
          corporateNames: [
            {
              value: dataClient.company?.fiscal?.corporateName,
            },
          ],
          matriculation: dataClient.company?.fiscal?.matriculation,
          taxCode: dataClient.company?.fiscal?.taxCode,
        },
        website: dataClient.company?.website,
        socials: arraySocials,
      },
      accountSettings: {
        currency: dataClient.accountSettings?.currency as CurrencyCode,
        language: dataClient.accountSettings?.language as LanguageCode,
      },
    });
    setNameError({ alphanumeric: true, specialCaratcters: true });
  }, [dataClient]);

  const handleChange = (name: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormClient({
      ...formClient,
      company: {
        ...formClient.company,
        [name]: event.target.value,
      },
    });
  };

  let listActivities: CompanyActivityInput[] = [];
  const handleBlurActivities = () => {
    activities.map((item: string) => listActivities.push({ activity: item }));
    setFormClient({
      ...formClient,
      company: {
        ...formClient.company,
        activities: listActivities,
      },
    });
  };

  const handleAccountSettings = (name: string) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setFormClient({
      ...formClient,
      accountSettings: {
        ...formClient.accountSettings,
        [name]: event.target.value as string,
      },
    });
  };

  const handleSocail = (name: string) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    if (name === "kind") {
      const kind = social?.getListSocialKinds?.list?.filter(
        (item) => item.id === event.target.value
      );
      setselectedNet({
        ...selectedNet,
        kind: event.target.value as string,
        value: getURLSocialMedia(kind?.[0]?.name),
      });
    } else {
      setselectedNet({
        ...selectedNet,
        [name]: event.target.value as string,
      });
    }
  };

  const handleAddSocial = () => {
    let ListSocial = Object.assign([], formClient?.company?.socials);
    ListSocial.push(selectedNet);
    setselectedNet({ ...selectedNet, value: undefined });
    setFormClient({
      ...formClient,
      company: {
        ...formClient.company,
        socials: ListSocial,
      },
    });
  };

  const getSocial = (idSocial: string) => {
    return social?.getListSocialKinds.list.filter(
      (socialNet) => socialNet.id === idSocial
    );
  };

  const handleCaracteristic = (name: CompanyCharacteristicKind) => (
    event: any
  ) => {
    let caracteristics: CompanyCharacteristicInput[] = Object.assign(
      [],
      formClient.company?.characteristics
    );
    caracteristics[_.findIndex(caracteristics, { kind: name })].value =
      event.target.value;

    setFormClient({
      ...formClient,
      company: {
        ...formClient.company,
        characteristics: caracteristics,
      },
    });
  };

  const handleDateChange = (date: Date | null) => {
    setFormClient({
      ...formClient,
      accountActivation: {
        ...formClient.accountActivation,
        activationDate: date,
      },
    });
    setErrorForm({
      ...errorForm,
      activationDate: "",
    });
  };

  //function close Snackbar
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  React.useEffect(() => {
    if (
      errorForm?.activities ||
      errorForm?.socialReson ||
      errorForm?.matriculation ||
      errorForm?.tva ||
      errorForm?.postalCode ||
      errorForm?.address ||
      errorForm?.countryCodeCompany ||
      errorForm?.phoneCompany
    ) {
      companyInformation.current?.scrollIntoView();
    } else if (
      errorForm?.firstName ||
      errorForm?.lastName ||
      errorForm?.countryCodePers ||
      errorForm?.phonePers
    ) {
      personalInformation.current?.scrollIntoView();
    } else if (errorForm?.activationDate) {
      packes.current?.scrollIntoView();
    }
  }, [
    errorForm,
    companyInformation,
    personalInformation,
    generalSetting,
    account,
    packes,
  ]);

  const handleSubmit = () => {
    const validation = Validation(formClient);
    if (
      Object.entries(validation).length === 0 &&
      nameError.alphanumeric &&
      nameError.specialCaratcters
    ) {
      dataClient.id &&
        UpdateUser({
          variables: {
            input: {
              token: token,
              id: dataClient.id,
              email: formClient.email,
              kind: formClient.kind,
              accountActivation: formClient.accountActivation,
              accountSettings: {
                currency: formClient.accountSettings?.currency as CurrencyCode,
                language: formClient.accountSettings?.language as LanguageCode,
              },
              company: formClient.company,
              contact: formClient.contact,
              informations: formClient.informations,
            },
          },
        });
    } else {
      setSnackbarMessages("check_the_required_fields");
      setErrorForm(validation);
      setOpen(true);
    }
  };

  const getCheckSpecialCaratcters = () => {
    switch (nameError.specialCaratcters) {
      case undefined:
        return "initial";
      case true:
        return "success";
      case false:
        return "error";
    }
  };
  const getCheckAlphanumeric = () => {
    switch (nameError.alphanumeric) {
      case undefined:
        return "initial";
      case true:
        return "success";
      case false:
        return "error";
    }
  };

  return (
    <Wrapper>
      <div className="topbar">
        <Note>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#AFB1C6" />
          <span className="info">
            {intl.formatMessage({ id: "listOfClients.info1" })}
            <br />
            {intl.formatMessage({ id: "listOfClients.info2" })}
          </span>
        </Note>
        <Button
          style={{ color: "#FFFFFF", position: "relative" }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleSubmit}
          disabled={loading}
        >
          <FontAwesomeIcon icon={faPlus} color="#ffffff" />
          {loading && (
            <CircularProgress size={24} style={{ position: "absolute" }} />
          )}
          <span className="btn-description">
            {intl.formatMessage({ id: "formClient.save" })}
          </span>
        </Button>
      </div>
      <Box p={20} width={1}>
        <h3 ref={companyInformation}>
          {intl.formatMessage({ id: "formClient.companyInformation" })}
        </h3>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({ id: "formClient.DomainName" })}
          </span>
          <div className="box-row">
            <Input
              id="domain"
              label={intl.formatMessage({ id: "formClient.DomainName" })}
              name="Nom de domaine"
              value={dataClient.company?.domain || ""}
              style={{
                width: "630px",
              }}
              required
              disabled
            />
            <div className="checklist">
              <div className="tip"></div>
              <ul>
                <li className={getCheckAlphanumeric()}>
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({
                    id: "formClient.alphanumericCharacters",
                  })}
                </li>
                <li className={getCheckSpecialCaratcters()}>
                  <FontAwesomeIcon icon={faCheckCircle} size="sm" />
                  {intl.formatMessage({
                    id: "formClient.noSpecialCharacters",
                  })}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.domainOfActivity",
            })}
          </span>
          <div className="box-row">
            <SelectInput
              multiple
              label={intl.formatMessage({
                id: "formClient.domainOfActivity",
              })}
              value={activities}
              onChange={(e) => {
                setActivities(e.target.value as string[]);
                setErrorForm({
                  ...errorForm,
                  activities: "",
                });
              }}
              onBlur={handleBlurActivities}
              renderValue={(selected) =>
                (getActivities(selected as string[]) as string[]).join(", ")
              }
              fullWidth
              style={{ maxWidth: "630px" }}
              required
              msgError={errorForm.activities}
            >
              {Activities?.getListCompanyActivities.list.map((activity) => (
                <MenuItem key={activity.id!} value={activity.id || ""}>
                  <Checkbox
                    checked={
                      activities
                        ? activities.indexOf(activity.id || "") > -1
                        : false
                    }
                    color="primary"
                  />
                  <ListItemText primary={activity.name} />
                </MenuItem>
              ))}
            </SelectInput>
          </div>
        </div>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.taxDetails",
            })}
          </span>
          <div className="box-row">
            <Input
              id="socialReson"
              label={intl.formatMessage({
                id: "formClient.socialReason",
              })}
              name="socialReson"
              value={
                formClient?.company?.fiscal?.corporateNames?.[0].value || ""
              }
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    fiscal: {
                      ...formClient.company?.fiscal,
                      corporateNames: [
                        { languageCode: "EN", value: e.target.value },
                      ],
                    },
                  },
                });
                setErrorForm({ ...errorForm, socialReson: "" });
              }}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.socialReson}
            />
            <Input
              id="matriculation"
              label={intl.formatMessage({
                id: "formClient.matriculation",
              })}
              name="matriculation"
              value={formClient?.company?.fiscal?.matriculation || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    fiscal: {
                      ...formClient.company?.fiscal,
                      matriculation: e.target.value,
                    },
                  },
                });
                setErrorForm({ ...errorForm, matriculation: "" });
              }}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.matriculation}
            />
            <Input
              id="tva"
              label={intl.formatMessage({
                id: "formClient.tvaCode",
              })}
              name="tva"
              value={formClient?.company?.fiscal?.taxCode || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    fiscal: {
                      ...formClient.company?.fiscal,
                      taxCode: e.target.value,
                    },
                  },
                });
                setErrorForm({ ...errorForm, tva: "" });
              }}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.tva}
            />
          </div>
        </div>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.address",
            })}
          </span>
          <div className="box-row">
            <SelectInput
              label={intl.formatMessage({
                id: "formClient.country",
              })}
              value={formClient?.company?.address?.country || ""}
              onChange={(e) =>
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    address: {
                      ...formClient.company?.address,
                      country: e.target.value as string,
                    },
                  },
                })
              }
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
            >
              {listCountry?.getListCountries.list.map((country) => {
                return (
                  <MenuItem key={country.id || ""} value={country.id || ""}>
                    {country.name}
                  </MenuItem>
                );
              })}
            </SelectInput>
            <SelectInput
              label={intl.formatMessage({
                id: "formClient.city",
              })}
              value={formClient?.company?.address?.city || ""}
              onChange={(e) =>
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    address: {
                      ...formClient.company?.address,
                      city: e.target.value as string,
                    },
                  },
                })
              }
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
            >
              {listCities?.getListCities.list.map((city) => {
                return (
                  <MenuItem key={city.id || ""} value={city.id || ""}>
                    {city.name}
                  </MenuItem>
                );
              })}
            </SelectInput>
            <Input
              id="postalCode"
              label={intl.formatMessage({
                id: "formClient.postalCode",
              })}
              name="postalCode"
              type="number"
              value={formClient?.company?.address?.postalCode || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    address: {
                      ...formClient.company?.address,
                      postalCode: parseInt(e.target.value),
                    },
                  },
                });
                setErrorForm({ ...errorForm, postalCode: "" });
              }}
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.postalCode}
            />
            <Input
              id="Address"
              label={intl.formatMessage({
                id: "formClient.address",
              })}
              name="Address"
              value={formClient?.company?.address?.roadNames?.[0].value || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    address: {
                      ...formClient.company?.address,
                      roadNames: [
                        { languageCode: "EN", value: e.target.value },
                      ],
                    },
                  },
                });
                setErrorForm({ ...errorForm, address: "" });
              }}
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.address}
            />
          </div>
        </div>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.telephoneNumber",
            })}
          </span>
          <div className="box-row">
            <Input
              id="countryCodeCompany"
              label={intl.formatMessage({
                id: "formClient.countryCode",
              })}
              name="countryCodeCompany"
              type="number"
              value={
                formClient?.company?.contacts?.[0].phone?.countryCode || ""
              }
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    contacts: [
                      {
                        ...formClient.company?.contacts?.[0],
                        phone: {
                          ...formClient.company?.contacts?.[0]?.phone,
                          countryCode: parseInt(e.target.value),
                        },
                      },
                    ],
                  },
                });
                setErrorForm({ ...errorForm, countryCodeCompany: "" });
              }}
              style={{
                width: "100px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.countryCodeCompany}
            />
            <Input
              id="phoneCompany"
              label={intl.formatMessage({
                id: "formClient.phone",
              })}
              name="phoneCompany"
              type="number"
              value={formClient?.company?.contacts?.[0].phone?.number || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  company: {
                    ...formClient.company,
                    contacts: [
                      {
                        ...formClient.company?.contacts?.[0],
                        phone: {
                          ...formClient.company?.contacts?.[0]?.phone,
                          number: e.target.value,
                        },
                      },
                    ],
                  },
                });
                setErrorForm({ ...errorForm, phoneCompany: "" });
              }}
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.phoneCompany}
            />
          </div>
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3 ref={personalInformation}>
          {intl.formatMessage({
            id: "formClient.personalInformation",
          })}
        </h3>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.firstContact",
            })}
          </span>
          <div className="box-row">
            <Input
              id="firstName"
              label={intl.formatMessage({
                id: "formClient.firstName",
              })}
              name="firstName"
              value={formClient?.informations?.firstName || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  informations: {
                    ...formClient.informations,
                    firstName: e.target.value,
                  },
                });
                setErrorForm({
                  ...errorForm,
                  firstName: "",
                });
              }}
              style={{
                width: "350px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.firstName}
            />
            <Input
              id="lastName"
              label={intl.formatMessage({
                id: "formClient.lastName",
              })}
              name="lastName"
              value={formClient?.informations?.lastName || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  informations: {
                    ...formClient.informations,
                    lastName: e.target.value,
                  },
                });
                setErrorForm({
                  ...errorForm,
                  lastName: "",
                });
              }}
              style={{
                width: "350px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.lastName}
            />
          </div>
        </div>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.telephoneNumber",
            })}
          </span>
          <div className="box-row">
            <Input
              id="countryCode"
              label={intl.formatMessage({
                id: "formClient.countryCode",
              })}
              name="countryCode"
              type="number"
              value={formClient?.contact?.phone?.countryCode || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  contact: {
                    ...formClient.contact,
                    phone: {
                      ...formClient.contact?.phone,
                      countryCode: parseInt(e.target.value),
                    },
                  },
                });
                setErrorForm({ ...errorForm, countryCodePers: "" });
              }}
              style={{
                width: "100px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.countryCodePers}
            />
            <Input
              id="phone"
              label={intl.formatMessage({
                id: "formClient.phone",
              })}
              name="phone"
              type="number"
              value={formClient?.contact?.phone?.number || ""}
              onChange={(e) => {
                setFormClient({
                  ...formClient,
                  contact: {
                    ...formClient.contact,
                    phone: {
                      ...formClient.contact?.phone,
                      number: e.target.value,
                    },
                  },
                });
                setErrorForm({ ...errorForm, phonePers: "" });
              }}
              style={{
                width: "250px",
                marginRight: "30px",
              }}
              required
              msgError={errorForm.phonePers}
            />
          </div>
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3 ref={generalSetting}>
          {intl.formatMessage({
            id: "formClient.generalSettings",
          })}
        </h3>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.generalAccountSettings",
            })}
          </span>
          <div className="box-row">
            <SelectInput
              label={intl.formatMessage({
                id: "formClient.primaryLanguage",
              })}
              value={formClient?.accountSettings?.language || ""}
              onChange={handleAccountSettings("language")}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
              required
            >
              {/* {language?.getListLanguages.list.map((lang, index) => {
                return (
                  <MenuItem key={index} value={lang.code || ""}>
                    {lang.code && getLanguage(lang.code)}
                  </MenuItem>
                );
              })} */}
              <MenuItem value="FR">English</MenuItem>
              <MenuItem value="EN">French</MenuItem>
            </SelectInput>
            <SelectInput
              label={intl.formatMessage({
                id: "formClient.currency",
              })}
              value={formClient?.accountSettings?.currency || ""}
              onChange={handleAccountSettings("currency")}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
              required
            >
              {Currencies?.getListCurrencies.list.map((currency, index) => {
                return (
                  <MenuItem key={index} value={currency.code || ""}>
                    {currency.code}
                  </MenuItem>
                );
              })}
            </SelectInput>
          </div>
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3>
          {intl.formatMessage({
            id: "formClient.socialMedia",
          })}
        </h3>
        <div className="box" style={{ marginBottom: "15px" }}>
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.Website",
            })}
          </span>
          <div className="box-row">
            <Input
              id="website"
              label={intl.formatMessage({
                id: "formClient.Website",
              })}
              name="website"
              value={formClient?.company?.website || ""}
              onChange={handleChange("website")}
              fullWidth
            />
          </div>
        </div>
        <div className="box" style={{ padding: "5px 0 0px 0" }}>
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.selectSocialMedia",
            })}
          </span>
          <div className="social">
            <SelectInput
              label={intl.formatMessage({
                id: "formClient.socialMedia",
              })}
              value={selectedNet.kind || ""}
              onChange={handleSocail("kind")}
              style={{
                width: "250px",
                marginRight: "30px",
              }}
            >
              {social &&
                social.getListSocialKinds.list.map((socialNet, index) => {
                  return (
                    <MenuItem key={index} value={socialNet.id || ""}>
                      {socialNet.name}
                    </MenuItem>
                  );
                })}
            </SelectInput>
            <Input
              id="link"
              label={intl.formatMessage({
                id: "formClient.link",
              })}
              name="link"
              value={selectedNet.value || ""}
              onChange={handleSocail("value")}
              fullWidth
              style={{ marginBottom: "-24px" }}
            />
            <Fab
              style={{ marginTop: "auto", marginBottom: "3px" }}
              aria-label="add"
              color="primary"
              onClick={handleAddSocial}
              disabled={!selectedNet.kind}
            >
              <FontAwesomeIcon icon={faPlus} size="lg" color="#ffffff" />
            </Fab>
          </div>
          {formClient?.company?.socials?.map((selectedNet, index) => {
            return (
              <div key={index} className="social-link">
                {selectedNet.kind &&
                  social?.getListSocialKinds.list &&
                  social?.getListSocialKinds.list.length > 0 && (
                    <React.Fragment>
                      <img
                        src={getSocialIcon(
                          getSocial(selectedNet.kind)?.[0].name!
                        )}
                        alt="social network logo"
                      />
                      <span>{getSocial(selectedNet.kind!)?.[0].name!}</span>
                      <hr />
                      <span>{selectedNet.value}</span>
                    </React.Fragment>
                  )}
              </div>
            );
          })}
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3 ref={account}>
          {intl.formatMessage({
            id: "formClient.account",
          })}
        </h3>
        <span className="input-label">
          {intl.formatMessage({
            id: "formClient.userType",
          })}
        </span>
        <div className="box-row">
          <SelectInput
            label={intl.formatMessage({
              id: "formClient.user",
            })}
            value={formClient.kind || ""}
            onChange={(e) =>
              setFormClient({
                ...formClient,
                kind: e.target.value as string,
              })
            }
            style={{
              width: "300px",
              marginRight: "30px",
            }}
          >
            {kind &&
              kind.getListUserKinds.list.map((userKind, index) => {
                return (
                  <MenuItem key={index} value={userKind.id || ""}>
                    {userKind.name}
                  </MenuItem>
                );
              })}
          </SelectInput>
          <Input
            id="email"
            label={intl.formatMessage({
              id: "formClient.email",
            })}
            name="email"
            value={formClient?.email || ""}
            onChange={(e) => {
              setFormClient({
                ...formClient,
                email: e.target.value as string,
              })
            }}
            style={{
              width: "300px",
            }}
            autoComplete="nope"
          />
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3>
          {intl.formatMessage({
            id: "formClient.privileges",
          })}
        </h3>
        <div className="box">
          <span className="input-label">
            {intl.formatMessage({
              id: "formClient.ParametersAndTypeOfPack",
            })}
          </span>
          <div className="box-row">
            <SelectInput
              id="multiSites"
              label={intl.formatMessage({
                id: "formClient.multiSites",
              })}
              name="multiSites"
              value={
                formClient?.company?.characteristics?.[
                  _.findIndex(formClient.company.characteristics, {
                    kind: "MULTI_SITES",
                  })
                ].value || ""
              }
              onChange={handleCaracteristic("MULTI_SITES")}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
            >
              <MenuItem value={"ON"}>ON</MenuItem>
              <MenuItem value={"OFF"}>OFF</MenuItem>
            </SelectInput>
            <SelectInput
              id="orderExperience"
              label={intl.formatMessage({
                id: "formClient.orderingExperience",
              })}
              name="orderExperience"
              value={
                formClient?.company?.characteristics?.[
                  _.findIndex(formClient.company.characteristics, {
                    kind: "ORDER_EXPERIENCE",
                  })
                ].value || ""
              }
              onChange={handleCaracteristic("ORDER_EXPERIENCE")}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
            >
              <MenuItem value={"ON"}>ON</MenuItem>
              <MenuItem value={"OFF"}>OFF</MenuItem>
            </SelectInput>
            <Input
              id="numberOfMenu"
              label={intl.formatMessage({
                id: "formClient.numberOfMenusAllowed",
              })}
              name="numberOfMenu"
              value={
                formClient?.company?.characteristics?.[
                  _.findIndex(formClient.company.characteristics, {
                    kind: "NUMBER_OF_MENUS",
                  })
                ].value || ""
              }
              onChange={handleCaracteristic("NUMBER_OF_MENUS")}
              style={{
                width: "300px",
                marginRight: "30px",
              }}
            />
            <Input
              id="slides"
              label={intl.formatMessage({
                id: "formClient.numberOfSlidesAllowed",
              })}
              name="slides"
              value={
                formClient?.company?.characteristics?.[
                  _.findIndex(formClient.company.characteristics, {
                    kind: "NUMBER_OF_SLIDES",
                  })
                ].value || ""
              }
              onChange={handleCaracteristic("NUMBER_OF_SLIDES")}
              style={{
                width: "300px",
              }}
            />
          </div>
        </div>
      </Box>
      <Seperator />
      <Box p={20} width={1}>
        <h3 ref={packes}>
          {intl.formatMessage({
            id: "formClient.packes",
          })}
        </h3>
        <div className="box-row">
          <SelectInput
            label={intl.formatMessage({
              id: "formClient.accountStatus",
            })}
            value={formClient?.accountActivation?.activationStatus || ""}
            onChange={(e) =>
              setFormClient({
                ...formClient,
                accountActivation: {
                  ...formClient.accountActivation,
                  activationStatus: e.target.value as string,
                },
              })
            }
            style={{
              width: "300px",
              marginRight: "30px",
              marginBottom: "-24px",
            }}
          >
            {activationStatus &&
              activationStatus.getListActivationStatus.list.map(
                (status, index) => {
                  return (
                    <MenuItem key={index} value={status.id || ""}>
                      {status.name}
                    </MenuItem>
                  );
                }
              )}
          </SelectInput>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div style={{ width: 300 }}>
              <DatePicker
                label={intl.formatMessage({
                  id: "formClient.expirationDate",
                })}
                value={formClient?.accountActivation?.activationDate || null}
                onChange={handleDateChange}
                format="dd/MM/yyyy"
                fullWidth
                required
                msgError={errorForm.activationDate}
              />
            </div>
          </MuiPickersUtilsProvider>
        </div>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {snackbarMessages}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

export default UpdateClient;

const Wrapper = styled("div")`
  ${() => WrapperStyles()}
`;

const Note = styled("div")`
  ${() => NoteStyles()}
`;

const Seperator = styled("div")`
  ${() => SeperatorStyles()}
`;
