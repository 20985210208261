import { css } from "styled-components";

export const WrapperStyles = () => {
  return css`
    padding: 10px 20px 0 0;
    .topbar {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .info {
        margin-left: 15px;
        color: #afb1c6;
        font-weight: 100;
        font-style: italic;
        font-size: 13px;
      }
      .btn-description {
        margin-left: 12px;
        font-size: 16px;
        text-transform: lowercase;
        ::first-letter {
          text-transform: uppercase;
        }
      }
    }
    h3 {
      margin: 0 0 20px 0;
    }
    .box {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .checklist {
        height: 40px;
        background-color: ghostwhite;
        padding: 15px 5px 5px 10px;
        border-radius: 8px;
        width: 200px;
        margin-left: 20px;
        .tip {
          width: 0;
          height: 0;
          border-top: 7px solid transparent;
          border-right: 10px solid ghostwhite;
          border-bottom: 7px solid transparent;
          left: -9px;
          position: absolute;
          top: 7px;
        }
        ul {
          padding: 0;
          margin: 0;
          list-style: none;
          li {
            font-size: 13px;
            color: #868894;
            svg {
              margin-right: 8px;
            }
          }
          /* color: #0dca12; */
          li.success svg {
            color: #0dca12;
          }

          /* color: #f67070; */
          li.error {
            color: #f67070;
            svg {
              color: #f67070;
            }
          }

          li.initial svg {
            color: #afb1c6;
          }
        }
      }
    }
    .box-row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      > div {
        margin-top: 15px;
      }
    }
    .status {
      display: inline-flex;
      width: 60px;
      height: 25px;
      align-items: center;
      justify-content: center;
      background: #4cd964;
      color: #ffffff;
      border-radius: 5px;
      font-style: normal;
      margin-left: 15px;
    }
    .social {
      display: grid;
      grid-template-columns: 250px 1fr 60px;
      grid-gap: 30px;
      > div {
        margin-top: 15px;
      }
    }
    .social-link {
      padding: 15px;
      background: #fafbff;
      border-radius: 8px;
      margin: 15px 0 0 0;
      display: grid;
      grid-template-columns: 25px 100px 3px auto;
      grid-gap: 20px;
      span {
        margin: auto 0;
      }
      img {
        width: 100%;
      }
      svg {
      }
      hr {
        height: 100%;
        margin: auto;
        border: 0;
        border-left: 2px solid #dddee0;
      }
    }
  `;
};

export const SeperatorStyles = () => {
  return css`
    width: 100%;
    height: 20px;
  `;
};

export const NoteStyles = () => {
  return css`
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
      color: #afb1c6;
      font-weight: 100;
      font-style: italic;
    }
  `;
};
