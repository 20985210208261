export default {
  //Title in Layout & MainLayout

  "listOfClient.Title": "List of clients",
  "addNewCustomer.Title": "Add new customer",
  "editCustomer.Title": "Edit customer",
  "signOut.Title": "Sign out",
  "setting.Title": "Setting",
  "account.Title": "Account",

  //Description info

  "listOfClients.info1":
    "Manage your customers, add new ones to your list or modify ",
  "listOfClients.info2": "and delete existing customers!",

  //Page list of clients

  "listOfClients.add": "Add customer",
  "listOfClients.socialReason": "Social reason",
  "listOfClients.domain": "Domain",
  "listOfClients.email": "Email",
  "listOfClients.firstName": "First name",
  "listOfClients.lastName": "Last name",
  "listOfClients.address": "Address",
  "listOfClients.phoneNumber": "Phone number",
  "listOfClients.createdAt": "Created At",
  "listOfClients.actions": "Actions",
  "listOfClients.edit": "Edit",
  "listOfClients.delete": "Delete",
  "listOfClients.lignesPerPage": "Lines per page",
  "listOfClients.of": "Of",
  "listOfClients.placehoderSearch": "Type to search...",

  //Page update & create client

  "formClient.save": "Save",
  "formClient.companyInformation": "Company information",
  "formClient.DomainName": "Domain name",
  "formClient.alphanumericCharacters": "Alphanumeric characters",
  "formClient.noSpecialCharacters": "No special characters",
  "formClient.domainOfActivity": "Domain of activity",
  "formClient.taxDetails": "Tax details",
  "formClient.socialReason": "Social reason",
  "formClient.matriculation": "Matriculation",
  "formClient.tvaCode": "TVA code",
  "formClient.address": "Address",
  "formClient.country": "Country",
  "formClient.city": "City",
  "formClient.postalCode": "Postal code",
  "formClient.telephoneNumber": "Telephone number",
  "formClient.countryCode": "Country code",
  "formClient.phone": "Phone",
  "formClient.personalInformation": "Personal information",
  "formClient.firstContact": "First contact",
  "formClient.firstName": "First name",
  "formClient.lastName": "Last name",
  "formClient.generalSettings": "General settings",
  "formClient.generalAccountSettings": "General account settings",
  "formClient.primaryLanguage": "Primary language",
  "formClient.currency": "Currency",
  "formClient.socialMedia": "Social media",
  "formClient.Website": "Website",
  "formClient.selectSocialMedia": "Select social media",
  "formClient.link": "Link",
  "formClient.account": "Account",
  "formClient.userType": "User type",
  "formClient.user": "User",
  "formClient.email": "Email",
  "formClient.password": "Password",
  "formClient.confirmPassword": "Confirm password",
  "formClient.privileges": "Privileges",
  "formClient.ParametersAndTypeOfPack": "Parameters and type of pack",
  "formClient.multiSites": "Multi sites",
  "formClient.orderingExperience": "Ordering experience",
  "formClient.numberOfMenusAllowed": "Number of menus allowed",
  "formClient.numberOfSlidesAllowed": "Number of slides allowed",
  "formClient.packes": "Packes",
  "formClient.accountStatus": "Account status",
  "formClient.expirationDate": "Expiration date",
  "formClient.cancel": "Cancel",
  "formClient.changePass" : "Update password",

  //page settings
  "Settings.title": "Settings",
  "Settings.label": "Menu languages",

  //alert

  "domainAlreadyExists.Alert": "Domain already exists",
  "emailAlreadyExists.Alert": "Email already exists",
  "checkTheRequiredFields.Alert": "Check the required fields",
};
