import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSortDown } from "@fortawesome/free-solid-svg-icons";

type BreadcrumbsType = {
  links: object[] | undefined;
};

const Breadcrumbs: React.FC<BreadcrumbsType> = ({ links }) => {
  const history = useHistory();
  const verifyURL = (link: any) => (event: React.SyntheticEvent) => {
    if (history.location.pathname === `${"/" + link.link}`)
      event.preventDefault();
  };

  return (
    <Wrapper>
      {links !== undefined &&
        links.map((link: any, index: number) => {
          return (
            <Crumbs key={index}>
              {index !== 0 && (
                <FontAwesomeIcon
                  style={{ transform: "rotate(-90deg", marginRight: "10px" }}
                  icon={faSortDown}
                  color="#00004e"
                />
              )}
              <Link href={"/" + link.link} onClick={verifyURL(link)}>
                <span className="link">{link.value}</span>
              </Link>
            </Crumbs>
          );
        })}
    </Wrapper>
  );
};

const Crumbs = styled.div`
  .link {
    margin-right: 5px;
  }
  .link::first-letter {
    text-transform: uppercase;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  div::first-letter {
    text-transform: uppercase;
  }
`;

export default Breadcrumbs;
