import { useMutation } from "@apollo/react-hooks";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, CircularProgress } from "@material-ui/core";
import { gql } from "apollo-boost";
import React from "react";
import { useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { GETLISTUSERS } from "../../api";
import {
  GluPayload,
  UpdateUserPasswordInput,
  UpdateUserPasswordPayload,
} from "../../api/types";
import { Input } from "../../commons";
import { SeperatorStyles, WrapperStyles } from "./styles";

type UpdateClientPasswordMutationVariables = {
  input: UpdateUserPasswordInput;
};

type UpdateClientPasswordMutation = {
  __typename?: "Mutation" | undefined;
} & {
  updateUserPassword: {
    __typename?: "UpdateUserPasswordPayload";
  } & Pick<UpdateUserPasswordPayload, "success">;
};

const UPDATECLIENTPASSWORD = gql`
  mutation UpdateUserPassword($input: UpdateUserPasswordInput!) {
    updateUserPassword(input: $input) {
      success
    }
  }
`;

type UpdateClientPasswordProps = {
  dataClient: GluPayload | undefined;
  onClose: () => void;
};

const UpdateClientPassword: React.FC<UpdateClientPasswordProps> = ({
  dataClient,
  onClose,
}) => {
  const token = localStorage.getItem("token") || "";
  const history = useHistory();
  const intl = useIntl();
  const [newPassword, setNewPassword] = React.useState<string>("");

  const [updateUserPassword, { loading }] = useMutation<
    UpdateClientPasswordMutation,
    UpdateClientPasswordMutationVariables
  >(UPDATECLIENTPASSWORD, {
    onCompleted: () => {
      history.push("/");
    },
    onError: (error) => {
      if (error?.graphQLErrors?.[0]?.message) {
        // setSnackbarMessages("domain_already_exists");
        // setOpen(true);
        console.log(error?.graphQLErrors?.[0]?.message);
      }
    },
    refetchQueries: () => [
      {
        query: GETLISTUSERS,
        variables: {
          input: {
            token: token,
            languageCode: "EN",
            kind: "MANAGER",
            pageSize: 10,
            pageNumber: 1,
          },
        },
      },
    ],
  });

  const handleSubmit = () => {
    updateUserPassword({
      variables: {
        input: {
          token: token,
          id: dataClient?.id || "",
          newPassword,
        },
      },
    });
  };
  return (
    <Wrapper>
      <Box>
        <div className="box">
          <Input
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            id="password"
            label={intl.formatMessage({
              id: "formClient.password",
            })}
            name="password"
            style={{
              width: "300px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              style={{ position: "relative", margin: "0 1vw" }}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
              <span className="btn-description">
                {intl.formatMessage({ id: "formClient.save" })}
              </span>
            </Button>
            <Button
              style={{ position: "relative" }}
              variant="contained"
              disableElevation
              color="secondary"
              onClick={onClose}
            >
              {intl.formatMessage({ id: "formClient.cancel" })}
            </Button>
          </div>
        </div>
      </Box>
    </Wrapper>
  );
};

export default UpdateClientPassword;

const Wrapper = styled("div")`
  ${() => WrapperStyles()}
`;

const Seperator = styled("div")`
  ${() => SeperatorStyles()}
`;
