import React, { useEffect } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";
import Modal from "../commons/Modal";

type FileType = {
  file: FileList | null;
  progress: number;
  onComplete: (value: string) => void;
};

type ProgressType = {
  progress: number;
};

const File: React.FC<FileType> = ({ file, progress, onComplete }) => {
  const [image, setImage] = React.useState();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  useEffect(() => {
    if (file && file[0] !== null && file[0] !== undefined) {
      let reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (e: any) => {
        setImage(e.target.result);
      };
    }
  }, [file]);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleValues = () => {
    setOpenModalDelete(false);
  };

  const handleCancel = () => {
    console.log("cancel");
  };

  return (
    <Wrapper progress={progress}>
      <Modal
        open={openModalDelete}
        title="Supprimer image"
        handleClose={handleClose}
        handleContent={handleValues}
        message="voulez-vous supprimer cette image?"
        action="delete"
      />
      <img src={image} alt="file preview" className="file-preview" />
      <div className="file-upload-container">
        <label className="file-name">{file && file[0].name}</label>
        <Tooltip
          title={progress < 100 ? "Annuler" : "Supprimer"}
          placement="bottom"
        >
          <button
            className="cancel-btn"
            onClick={() => {
              progress < 100 ? handleCancel() : setOpenModalDelete(true);
            }}
          >
            <FontAwesomeIcon
              icon={progress < 100 ? faTimes : faTrash}
              color="#F67070"
              size="sm"
            />
          </button>
        </Tooltip>
      </div>
      <div className="actions">
        <Progress progress={progress}>
          <label className="contrast">Téléchargement</label>
          <div className="progress-status">
            <label
              className={progress < 100 ? "percentage" : "percentage done"}
            >
              {progress < 100 ? "Téléchargement" : "Téléchargé"}
            </label>
          </div>
        </Progress>
      </div>
    </Wrapper>
  );
};

const Progress = styled.div<ProgressType>`
  display: flex;
  justify-content: center;
  height: 24px;
  background-color: #f3f4f9;
  border-radius: 12px;
  align-items: center;
  color: #afb1c6;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  .percentage {
    color: white;
    margin-left: 35px;
  }
  label.done {
    margin-left: 49px;
  }
  .contrast {
    position: absolute;
    color: #afb1c6;
  }
  .progress-status {
    position: absolute;
    width: ${props => props.progress + "%"};
    background-color: ${props =>
      props.progress < 100 ? "#00004E" : "#4CD964"};
    left: 0;
    border-radius: 2px;
    transition: all 0.4s ease-in;
    height: 100%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }
`;

const Wrapper = styled.div<ProgressType>`
  background: #fafbff;
  border-radius: 10px;
  padding: 10px;
  display: grid;
  grid-template-rows: 100px auto auto;
  .file-upload-container {
    display: grid;
    grid-template-columns: auto 24px;
    padding: 10px 0 0 0;
  }
  .file-preview {
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: contain;
  }
  .cancel-btn {
    width: 24px;
    height: 24px;
    border: 0;
    outline: none;
    background-color: #fcf1f1;
    border-radius: 12px;
    margin: auto;
    cursor: pointer;
  }
  .actions {
    display: grid;
    grid-template-columns: auto;
    height: 34px;
    transition: all ease-in-out;
    overflow: hidden;
    animation: ${props =>
      props.progress === 100 && "show 0.4s ease-in-out 3s forwards"};
  }
  .file-name {
    color: rgba(97, 97, 97, 0.9);
    font-size: 14px;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
  }
  @keyframes show {
    0% {
      height: 34px;
    }
    100% {
      height: 0px;
    }
  }
`;

export default File;
