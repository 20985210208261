import gql from "graphql-tag";

export const GETLISTUSERKIND = gql`
  query GetListUSerKind($input: GetListUserKindsInput!) {
    getListUserKinds(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTSOCIALKINDS = gql`
  query GetListSocialKinds($input: GetListSocialKindsInput!) {
    getListSocialKinds(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTLANGUAGES = gql`
  query GetListLanguages {
    getListLanguages {
      list {
        id
        code
      }
    }
  }
`;

export const GETLISTCURRENCIES = gql`
  query GetListCurrencies {
    getListCurrencies {
      list {
        id
        code
      }
    }
  }
`;

export const GETLISTCOMPANYACTIVITIES = gql`
  query GetListCompanyActivities($input: GetListCompanyActivitiesInput!) {
    getListCompanyActivities(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTCOUNTRIES = gql`
  query getListCountries($name: String) {
    getListCountries(input: { name: $name }) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTCITIES = gql`
  query getListCities($countryId: String!, $name: String) {
    getListCities(input: { countryId: $countryId, name: $name }) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTACTIVATIONSTATUS = gql`
  query GetListActivationStatus($input: GetListActivationStatusInput!) {
    getListActivationStatus(input: $input) {
      list {
        id
        name
      }
    }
  }
`;

export const GETLISTUSERS = gql`
  query GetListUsers($input: GetListUsersInput!) {
    getListUsers(input: $input) {
      list {
        id
        email
        kind {
          id
          name
        }
        accountActivation {
          activationDate
          activationStatus {
            id
            name
          }
        }
        informations {
          firstName
          lastName
        }
        contact {
          address {
            id
            roadNames {
              id
              languageCode
              value
            }
            postalCode
            country {
              id
              name
            }
            city {
              id
              name
            }
          }
          phone {
            countryCode
            number
          }
        }
        accountSettings {
          language
          currency
        }
        company {
          id
          domain
          shortName
          longName
          description
          activities {
            activity {
              id
              name
            }
          }
          fiscal {
            corporateName
            legalForm {
              id
              name
            }
            matriculation
            taxCode
          }
          logo {
            id
            kind
            fileName
          }
          address {
            id
            roadNames {
              id
              languageCode
              value
            }
            postalCode
            country {
              id
              name
            }
            city {
              id
              name
            }
          }
          website
          characteristics {
            kind
            value
          }
          socials {
            kind {
              id
              name
            }
            value
          }
          contacts {
            phone {
              countryCode
              number
            }
          }
        }
        createdAt
      }
      count
    }
  }
`;

export const GETMYACCOUNTSETTINGS = gql`
  query GetMyAccountSettings($token: String!) {
    getMyAccountSettings(input: { token: $token }) {
      accountSettings {
        language
        currency
      }
    }
  }
`;
