import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faSignOutAlt,
  faUserFriends,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-ui/core/Tooltip";
import { useIntl } from "react-intl";
//import from local
import logo from "../images/logo.png";

const MainLayout: React.FC = ({ children, ...props }) => {
  const intl = useIntl();
  let history = useHistory();
  function getTitle() {
    switch (history.location.pathname) {
      case "/":
        return intl.formatMessage({ id: "listOfClient.Title" });
      case "/new_client":
        return intl.formatMessage({ id: "addNewCustomer.Title" });
      case "/update_client":
        return intl.formatMessage({ id: "editCustomer.Title" });
      case "/settings":
        return intl.formatMessage({ id: "Settings.title" });
      default:
        return "";
    }
  }

  return (
    <div>
      <TopBar>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Divider />
        <div className="topbar-container">
          <div className="topbar-actions">
            <div className="topbar-page-title">
              <h2>{getTitle()}</h2>
            </div>
            <ul>
              <Tooltip
                title={intl.formatMessage({ id: "signOut.Title" })}
                placement="bottom"
              >
                <li
                  onClick={() => {
                    history.push("/login");
                    localStorage.clear();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} color="#ffffff" />
                </li>
              </Tooltip>
            </ul>
          </div>
        </div>
      </TopBar>
      <Nav>
        <ul>
          <Tooltip
            title={intl.formatMessage({ id: "listOfClient.Title" })}
            placement="right"
          >
            <li
              onClick={() => {
                history.push("/");
              }}
            >
              <FontAwesomeIcon icon={faUserFriends} color="#ffffff" size="lg" />
            </li>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage({ id: "setting.Title" })}
            placement="right"
          >
            <li
              onClick={() => {
                history.push("/settings");
              }}
            >
              <FontAwesomeIcon icon={faCog} color="#ffffff" size="lg" />
            </li>
          </Tooltip>
        </ul>
       {/* <ul>
           <Tooltip
            title={intl.formatMessage({ id: "account.Title" })}
            placement="right"
          >
            <li
              onClick={() => {
                history.push("/account");
              }}
            >
              <FontAwesomeIcon icon={faUserAlt} color="#ffffff" size="lg" />
            </li>
          </Tooltip> 

        </ul>*/}
      </Nav>
      {children}
    </div>
  );
};

const Divider = styled.div`
  width: 1px;
  height: 80%;
  margin: auto;
  border: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
`;

const TopBar = styled.div`
  position: fixed;
  width: 100%;
  height: 65px;
  top: 0px;
  background: #00004e;
  display: grid;
  grid-template-columns: 100px 3px auto;
  .breadcrumbs::first-letter {
    text-transform: uppercase;
  }
  .MuiTypography-colorTextPrimary {
    color: rgb(255, 255, 255);
  }
  .MuiTypography-colorTextSecondary {
    color: rgba(255, 255, 255, 0.71);
  }
  .topbar-container {
    display: grid;
    grid-template-rows: 50% 50%;
    padding: 15px 0;
    .topbar-actions {
      display: grid;
      grid-template-columns: 50% 50%;
      margin: auto 25px;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: row-reverse;
        li {
          margin: 0 7px;
          list-style: none;
          display: inline-block;
          width: 50px;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, 0.1);
          cursor: pointer;
          border-radius: 18px;
        }
      }
    }
    .topbar-page-title {
      margin: 10px 25px auto 25px;
      color: white;
      h2 {
        margin: 0;
        padding: 0;
        text-align: left;
      }
    }
  }
  .logo {
    display: grid;
    img {
      width: 60%;
      margin: auto;
    }
  }
  .link {
    color: white;
  }
`;

const Nav = styled.div`
  position: fixed;
  width: 60px;
  left: 20px;
  top: 120px;
  background: #00004e;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ul {
    margin: 0;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    li {
      width: 50px;
      height: 60px;
      margin: 5px;
      border-radius: 25px;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      :hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
`;

export default MainLayout;
