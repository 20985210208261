export const required = "Ce champ est obligatoire";
export const invalid = "Champ invalid";

export const successfullyCreated = "Vous avez ajouté avec succès";
export const successfullyDeleted = "Vous avez supprimé avec succès";
export const successfullyUpdatetd =
  "Modification(s) enregistrée(s) avec succès";

export const emptyLogin = "Entrer votre Email";
export const emptyPassword = "Entrer votre mot de passe";
export const invalidLogin = "Email invalide";
export const invalidPassword = "Mot de passe invalide";
export const notMatching = "Ne sont pas correspondant";

//handle error from graphql
export const userNotFound = "Ce compte n'existe pas";
export const invalidPasswordGraphql = "Mot de passe incorrect";
export const microserviceUnvailable = "Une erreur est survenue";
