import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import { FilledInputProps } from "@material-ui/core/FilledInput";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { InputBase } from "@material-ui/core";

type InputProps = FilledInputProps & {
  label?: string;
  msgError?: string;
  fullWidth?: boolean;
  required?: boolean;
};

const Input: React.FC<InputProps> = ({
  label,
  msgError,
  fullWidth,
  required,
  ...restProps
}) => {
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor="component-filled" shrink required={required}>
        {label}
      </InputLabel>
      <InputBase inputProps={{ className: "mainInput" }} {...restProps} />
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  margin-bottom: 24px !important;
  .mainInput {
    overflow: "hidden";
    background-color: #ffffff;
    padding: 27px 12px 10px;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus {
      border: 2px solid #00004e;
      border-radius: 5px;
    }
    &:disabled {
      background-color: #dddddd5c;
    }
  }
  .Mui-error {
    .mainInput {
      border: 2px solid #f67070;
    }
  }
  .MuiButtonBase-root {
    background: #f1f2ff;
    margin-left: 2px;
  }
`;

export default Input;
