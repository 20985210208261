import gql from "graphql-tag";

//LOGIN
export const SIGNIN = gql`
  mutation signIn($login: String!, $password: String!) {
    signIn(input: { login: $login, password: $password }) {
      token
      kind
    }
  }
`;

export const CREATEUSER = gql`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

export const DELETEUSER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      success
    }
  }
`;

export const UPDATEUSER = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
    }
  }
`;

//mutattion update My Account Settings

export const UPDATEMYACCOUNTSETTINGS = gql`
  mutation UpdateMyAccountSettings($input: UpdateMyAccountSettingsInput!) {
    updateMyAccountSettings(input: $input) {
      success
    }
  }
`;
