import React from "react";
import { useHistory } from "react-router";
import { Box } from "../../commons";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import styled from "styled-components";

const ResetPassword: React.FC = props => {
  let history = useHistory();

  const [username, setUsername] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  return (
    <Wrapper>
      <Box p={24} width={400}>
        <div className="note">
          <p>
            Please enter your username or email address. You will receive a link
            to reset your password via email.
          </p>
        </div>
        <TextField
          id="username"
          label="Username"
          value={username}
          type="text"
          variant="filled"
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          style={{ marginBottom: "24px" }}
          onChange={handleChange}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ color: "#444444", margin: "15px 0" }}
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              history.push("/login");
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ color: "#FFFFFF", margin: "15px 0" }}
            variant="contained"
            color="primary"
            disableElevation
          >
            Reset Pasword
          </Button>
        </div>
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .note {
    background: #fafbff;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 10px 13px;
    margin-bottom: 24px;
    p {
      font-weight: 300;
      font-size: 14px;
      line-height: 15px;
      color: #afb1c6;
    }
  }
  .MuiFilledInput-underline:hover:before {
    border: 0;
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;

    :hover {
      background-color: #ffffff;
      border: 2px solid #f1f2fb;
    }
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
`;

export default ResetPassword;
