import React, { useEffect } from "react";
import styled from "styled-components";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

type DayType = {
  onCheck: (value: any) => void;
};

type WrapperType = {
  checked: boolean;
};

const weekState = {
  lundi: false,
  mardi: false,
  mercredi: false,
  jeudi: false,
  vendredi: false,
  samedi: false,
  dimanche: false
};

const Day: React.FC<DayType> = ({ onCheck }) => {
  const [week, setWeek] = React.useState(weekState);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeek({ ...week, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    onCheck(week);
  }, [onCheck, week]);

  return (
    <Container>
      <Tooltip title="Lundi">
        <Wrapper checked={week.lundi}>
          <Checkbox
            checked={week.lundi}
            onChange={handleChange}
            value="lundi"
            name="lundi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>L</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Mardi">
        <Wrapper checked={week.mardi}>
          <Checkbox
            checked={week.mardi}
            onChange={handleChange}
            value="mardi"
            name="mardi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>M</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Mercredi">
        <Wrapper checked={week.mercredi}>
          <Checkbox
            checked={week.mercredi}
            onChange={handleChange}
            value="mercredi"
            name="mercredi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>M</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Jeudi">
        <Wrapper checked={week.jeudi}>
          <Checkbox
            checked={week.jeudi}
            onChange={handleChange}
            value="jeudi"
            name="jeudi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>J</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Vendredi">
        <Wrapper checked={week.vendredi}>
          <Checkbox
            checked={week.vendredi}
            onChange={handleChange}
            value="vendredi"
            name="vendredi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>V</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Samedi">
        <Wrapper checked={week.samedi}>
          <Checkbox
            checked={week.samedi}
            onChange={handleChange}
            value="samedi"
            name="samedi"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>S</label>
        </Wrapper>
      </Tooltip>
      <Tooltip title="Dimanche">
        <Wrapper checked={week.dimanche}>
          <Checkbox
            checked={week.dimanche}
            onChange={handleChange}
            value="dimanche"
            name="dimanche"
            style={{ opacity: 0, position: "absolute" }}
          />
          <label>D</label>
        </Wrapper>
      </Tooltip>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto;
  margin: 20px 0;
`;

const Wrapper = styled.div<WrapperType>`
  overflow: hidden;
  position: relative;
  display: inline-flex;
  height: 31px;
  width: 31px;
  border-radius: 20px;
  background-color: ${props =>
    props.checked ? "rgba(116, 171, 255, 0.2)" : "#f1f2fb"};
  justify-content: center;
  align-items: center;
  border-width: 2px;
  border-style: solid;
  border-color: ${props =>
    props.checked ? "rgba(116, 171, 255, 0.2)" : "rgba(116, 171, 255, 0)"};
  transition: all 0.2s ease-in-out;
  label {
    font-size: 18px;
    font-weight: ${props => (props.checked ? 500 : 400)};
    color: ${props => (props.checked ? "#00004E" : "#afb1c6")};
  }
`;

export default Day;
