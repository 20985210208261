import { stateClient } from "./AddClient";
import {
  required,
  invalidLogin,
  invalidPassword,
  notMatching,
} from "../../utils";

export type Error = {
  domain: string;
  activities: string;
  socialReson: string;
  matriculation: string;
  tva: string;
  pays: string;
  city: string;
  postalCode: string;
  address: string;
  countryCodeCompany: string;
  phoneCompany: string;
  firstName: string;
  lastName: string;
  countryCodePers: string;
  phonePers: string;
  language: string;
  currency: string;
  kind: string;
  email: string;
  password: string;
  confirmPassword: string;
  activationStatus: string;
  activationDate: string;
};

export const Validation = (values: stateClient): Error => {
  let errors: Error = {
    domain: "",
    activities: "",
    socialReson: "",
    matriculation: "",
    tva: "",
    pays: "",
    city: "",
    postalCode: "",
    address: "",
    countryCodeCompany: "",
    phoneCompany: "",
    firstName: "",
    lastName: "",
    countryCodePers: "",
    phonePers: "",
    language: "",
    currency: "",
    kind: "",
    email: "",
    password: "",
    confirmPassword: "",
    activationStatus: "",
    activationDate: "",
  };

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "company":
        if (!values.company?.domain) {
          errors.domain = required;
        } else {
          delete errors.domain;
        }
        if (!values.company?.activities) {
          errors.activities = required;
        } else if (values.company?.activities.length === 0) {
          errors.activities = required;
        } else {
          delete errors.activities;
        }
        if (!values.company?.fiscal?.corporateNames?.[0]?.value) {
          errors.socialReson = required;
        } else {
          delete errors.socialReson;
        }
        if (!values.company?.fiscal?.matriculation) {
          errors.matriculation = required;
        } else {
          delete errors.matriculation;
        }
        if (!values.company?.fiscal?.taxCode) {
          errors.tva = required;
        } else {
          delete errors.tva;
        }
        if (!values.company?.address?.country) {
          errors.pays = required;
        } else {
          delete errors.pays;
        }
        if (!values.company?.address?.city) {
          errors.city = required;
        } else {
          delete errors.city;
        }
        if (!values.company?.address?.postalCode) {
          errors.postalCode = required;
        } else {
          delete errors.postalCode;
        }
        if (!values.company?.address?.roadNames?.[0]?.value) {
          errors.address = required;
        } else {
          delete errors.address;
        }
        if (!values.company?.contacts?.[0]?.phone?.countryCode) {
          errors.countryCodeCompany = required;
        } else {
          delete errors.countryCodeCompany;
        }
        if (!values.company?.contacts?.[0]?.phone?.number) {
          errors.phoneCompany = required;
        } else {
          delete errors.phoneCompany;
        }
        break;
      case "information":
        if (!values.information?.firstName) {
          errors.firstName = required;
        } else {
          delete errors.firstName;
        }

        if (!values.information?.lastName) {
          errors.lastName = required;
        } else {
          delete errors.lastName;
        }
        break;
      case "contact":
        if (!values.contact?.phone?.countryCode) {
          errors.countryCodePers = required;
        } else {
          delete errors.countryCodePers;
        }
        if (!values.contact?.phone?.number) {
          errors.phonePers = required;
        } else {
          delete errors.phonePers;
        }
        break;
      case "accountSettings":
        if (!values.accountSettings?.currency) {
          errors.currency = required;
        } else {
          delete errors.currency;
        }
        if (!values.accountSettings?.language) {
          errors.language = required;
        } else {
          delete errors.language;
        }
        break;
      case "kind":
        if (!values.kind) {
          errors.kind = required;
        } else {
          delete errors.kind;
        }
        break;
      case "email":
        if (!values.email) {
          errors.email = required;
        } else if (
          !values.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errors.email = invalidLogin;
        } else {
          delete errors.email;
        }
        break;
      case "password":
        if (!values.password) {
          errors.password = required;
        } else if (!(values.password.length > 5)) {
          errors.password = invalidPassword;
        } else {
          delete errors.password;
        }
        break;
      case "confirmPassword":
        if (!values.confirmPassword) {
          errors.confirmPassword = required;
        } else if (values.password !== values.confirmPassword) {
          errors.confirmPassword = notMatching;
        } else {
          delete errors.confirmPassword;
        }
        break;
      case "accountActivation":
        if (!values.accountActivation?.activationStatus) {
          errors.activationStatus = required;
        } else {
          delete errors.activationStatus;
        }
        if (!values.accountActivation?.activationDate) {
          errors.activationDate = required;
        } else {
          delete errors.activationDate;
        }
        break;
      default:
        break;
    }
  });

  return errors;
};
