import React, { FC } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faSort } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Modal from "./Modal";

type TableRowType = {
  data: object;
  index?: number;
};

type RowType = {
  rows: string;
  index?: number;
};

const TableRow: FC<TableRowType> = ({ data, index }) => {
  let history = useHistory();
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const handleClose = () => {
    setOpenModalDelete(false);
  };
  const getcolumns = (fields: number) => {
    let template: string = "auto";
    for (let i = 1; i < fields - 3; i++) {
      template = template.concat(" auto");
    }
    return template;
  };

  const handleDelete = () => {
    console.log("delete");
  };

  let products: string[] = [];
  index === -1
    ? (products = Object.keys(data).splice(0, Object.values(data).length - 2))
    : (products = Object.values(data).splice(
        0,
        Object.values(data).length - 2
      ));

  return (
    <Wrapper index={index} rows={getcolumns(Object.keys(data).length)}>
      <Modal
        open={openModalDelete}
        title="Supprimer produit"
        message="vou voulez supprimer ce produit"
        handleClose={handleClose}
        handleContent={handleDelete}
        action="delete"
      />
      <button className="handle">
        {index !== -1 && (
          <FontAwesomeIcon icon={faSort} size="sm" color="#AFB1C6" />
        )}
      </button>
      <span className="rank">
        {index !== -1 && index !== undefined && index + 1}
      </span>
      {products.map((value, index) => {
        return (
          <span key={index} className="table-row-content">
            {value}
          </span>
        );
      })}
      {index === -1 ? (
        <span style={{ margin: "auto" }}>Actions</span>
      ) : (
        <div>
          <Button
            style={{ color: "#FFFFFF", marginRight: "15px" }}
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => history.push("/item")}
          >
            <FontAwesomeIcon icon={faEdit} color="#ffffff" />
            <span className="btn-action"> éditer </span>
          </Button>
          <Button
            style={{ color: "#F67070", background: "#FCF1F1" }}
            variant="contained"
            disableElevation
            onClick={() => setOpenModalDelete(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
            <span className="btn-action"> Supprimer </span>
          </Button>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<RowType>`
  display: grid;
  grid-template-columns: 30px 20px 60px ${props => props.rows} 210px;
  padding: 8px;
  grid-gap: 15px;
  background-color: ${props =>
    props.index !== undefined && props.index % 2 !== 0 ? "#FAFBFF" : "white"};
  border-radius: 8px;
  margin: 2px 0;
  :hover {
    background-color: #f4f6ff;
  }
  .table-row-content {
    margin: auto 0;
  }
  span:nth-child(3) {
    margin: auto;
  }
  span:first-letter {
    text-transform: uppercase;
  }
  span.rank {
    margin: auto;
    font-size: 14px;
    color: #afb1c6;
  }
  .btn-action {
    padding: 1px 0;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  button.handle {
    width: 30px;
    height: 30px;
    margin: auto;
    border: 0;
    background: transparent;
    outline: none;
    :hover {
      cursor: grab;
    }
    :focus {
      cursor: grabbing;
    }
  }
`;

export default TableRow;
