import React, { FC } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

type ProductCardType = {
  progress: "up" | "down";
};

const ProductCard: FC<ProductCardType> = ({ progress }) => {
  return (
    <Wrapper>
      <img
        src="https://i.pinimg.com/564x/ac/05/99/ac0599e196e0afb44d3de3706f8fd4a4.jpg"
        alt="pizza"
      />
      <div className="product-info">
        <label>pepperoni pizza</label>
        <label className="description">
          pizza with cr ust and tomato sauce
        </label>
      </div>
      <div className="figure">
        <label className={"progress " + progress}>354</label>
        {progress === "up" ? (
          <FontAwesomeIcon icon={faArrowUp} size="sm" className={progress} />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} size="sm" className={progress} />
        )}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 40px auto 60px;
  grid-gap: 10px;
  padding: 10px;
  background: #fafbff;
  border-radius: 10px;
  margin-bottom: 15px;
  .product-info {
    display: grid;
    grid-template-rows: auto auto;
    label::first-letter {
      text-transform: uppercase;
    }
    .description {
      font-size: 13px;
      color: #c3c0d0;
    }
  }
  .figure {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 12px;
    }
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    object-fit: cover;
  }
  label.progress {
    display: block;
    text-align: center;
    margin: auto 5px;
  }
  .up {
    color: #4cd964;
  }
  .down {
    color: #f67070;
  }
`;

export default ProductCard;
