import React from "react";
import styled from "styled-components";
import { Route, Switch, useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
//import for local
import { MainLayout, Breadcrumbs } from "../../commons";
import { Clients, AddClient, UpdateCleint, Settings } from "../../modules";

const Layout: React.FC = () => {
  let history = useHistory();
  const intl = useIntl();
  function getKindContent(kind: string | null) {
    return (
      <Switch>
        <Route path={"/new_client"} component={AddClient} exact />
        <Route path={"/update_client"} component={UpdateCleint} exact />
        <Route path={"/settings"} component={Settings} exact />
        <Route path={"/"} component={Clients} />
      </Switch>
    );
  }

  const getBreadcrumbs = () => {
    switch (history.location.pathname) {
      case "/":
        return [
          { link: "", value: intl.formatMessage({ id: "listOfClient.Title" }) },
        ];
      case "/new_client":
        return [
          { link: "", value: "List des client" },
          {
            link: "new_client",
            value: intl.formatMessage({ id: "addNewCustomer.Title" }),
          },
        ];
      case "/update_client":
        return [
          { link: "", value: "List des client" },
          {
            link: "update_client",
            value: intl.formatMessage({ id: "editCustomer.Title" }),
          },
        ];
      case "/settings":
        return [
          {
            link: "settings",
            value: intl.formatMessage({ id: "Settings.title" }),
          },
        ];
      default:
        return [];
    }
  };

  return (
    <MainLayout>
      <Container>
        <Breadcrumbs links={getBreadcrumbs()} />
        {getKindContent(localStorage.getItem("kind"))}
      </Container>
    </MainLayout>
  );
};

const Container = styled.div`
  height: calc(100vh - 120px);
  width: calc(100% - 100px);
  margin: 80px 0 0 100px;
  overflow: auto;
  padding-bottom: 20px;

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 8px;
  }

  ::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dedede;
  }
`;

export default Layout;
