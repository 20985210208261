import React from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { Chip, Avatar } from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
//import from local
import { EN, FR } from "../../img";
//import api
import {
  GETMYACCOUNTSETTINGS,
  UPDATEMYACCOUNTSETTINGS,
  GETLISTCOMPANYACTIVITIES,
} from "../../api";
import {
  GetMyAccountSettingsQuery,
  GetMyAccountSettingsQueryVariables,
  UpdateMyAccountSettingsMutation,
  UpdateMyAccountSettingsMutationVariables,
  LanguageCode,
  CurrencyCode,
} from "../../api/types";

const languages = [
  { name: "English", code: "en", flag: EN },
  { name: "French", code: "fr", flag: FR },
];

const Settings: React.FC = () => {
  const token = localStorage.getItem("token") || "";
  const intl = useIntl();

  //query get my account setting
  const { data: dataAccount } = useQuery<
    GetMyAccountSettingsQuery,
    GetMyAccountSettingsQueryVariables
  >(GETMYACCOUNTSETTINGS, {
    variables: {
      token: token,
    },
  });

  //mutation update my account setting
  const [UpdateMyAccountSettings, { loading }] = useMutation<
    UpdateMyAccountSettingsMutation,
    UpdateMyAccountSettingsMutationVariables
  >(UPDATEMYACCOUNTSETTINGS);

  const selectedLanguage = dataAccount?.getMyAccountSettings?.accountSettings?.language?.toLowerCase();

  const handleChangeLanguage = (languageCode: string) => {
    UpdateMyAccountSettings({
      variables: {
        input: {
          token: token,
          currency: dataAccount?.getMyAccountSettings?.accountSettings
            ?.currency! as CurrencyCode,
          language: languageCode.toUpperCase() as LanguageCode,
        },
      },
      refetchQueries: () => [
        {
          query: GETMYACCOUNTSETTINGS,
          variables: { token: token },
        },
        {
          query: GETLISTCOMPANYACTIVITIES,
          variables: { input: { languageCode: languageCode.toUpperCase() } },
        },
      ],
    });
  };

  const nameLanguage = (language: string) => {
    switch (language) {
      case "English":
        return "English";
        break;
      case "French":
        return "Français";
        break;
      default:
        return "";
    }
  };

  return (
    <Wrapper>
      <div className="topbar">
        <Note>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#AFB1C6" />
          <div className="content">
            <span>
              {intl.formatMessage({ id: "listOfClients.info1" })}
              <br />
              {intl.formatMessage({ id: "listOfClients.info2" })}
            </span>
          </div>
        </Note>
      </div>
      <h2>{intl.formatMessage({ id: "Settings.title" })}</h2>
      <span style={{ fontSize: "14px" }}>
        {intl.formatMessage({ id: "Settings.label" })}
      </span>
      <div className="box" style={{ marginTop: "15px" }}>
        <form>
          {languages !== undefined &&
            languages.map((language, index) => {
              return (
                <Chip
                  key={index}
                  avatar={<Avatar alt={language.name} src={language.flag} />}
                  label={nameLanguage(language.name)}
                  onClick={() => handleChangeLanguage(language.code)}
                  color={
                    selectedLanguage === language.code ? "primary" : "default"
                  }
                  style={{ marginRight: "15px", marginBottom: "10px" }}
                  disabled={loading}
                />
              );
            })}
        </form>
      </div>
    </Wrapper>
  );
};

const Note = styled.div`
  display: flex;
  align-items: center;
  .content {
    display: flex;
    flex-direction: column;
    span {
      margin-left: 15px;
      color: #afb1c6;
      font-weight: 100;
      font-style: italic;
      font-size: 13px;
    }
  }
`;

const Wrapper = styled.div`
  padding: 20px 20px 0 0;
  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 15px 0;
  }
  .MuiChip-label {
    margin-top: 3px;
  }
`;

export default Settings;
