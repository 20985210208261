import React from "react";
import styled from "styled-components";
import {
  InputLabel,
  SelectProps,
  FormControl,
  FormHelperText,
  Select,
} from "@material-ui/core";

type InputProps = SelectProps & {
  label: string;
  msgError?: string;
  fullWidth?: boolean;
  required?: boolean;
};

const SelectInput: React.FC<InputProps> = ({
  label,
  msgError,
  children,
  fullWidth,
  required,
  ...restProps
}) => {
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor="component-filled" shrink required={required}>
        {label}
      </InputLabel>
      <Select inputProps={{ className: "MuiSelect" }} {...restProps}>
        {children}
      </Select>
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  margin-bottom: 24px !important;
  .MuiSelect {
    overflow: "hidden";
    background-color: #ffffff;
    padding: 27px 12px 10px;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    &:focus {
      background-color: #ffffff;
      border: 2px solid #00004e;
      border-radius: 5px;
    }
  }
  .Mui-error {
    .MuiSelect {
      border: 2px solid #f67070;
    }
  }
  .MuiFilledInput-root {
    border-radius: 5px;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
`;

export default SelectInput;
