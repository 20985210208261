import React, { FC } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlus,
  faEdit,
  faTrash,
  faSearch,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useIntl } from "react-intl";
//import from local
import { Modal, Box, TablePaginationActions, Input } from "../../commons";
//import apollo
import { useQuery, useMutation } from "@apollo/react-hooks";
import {
  GetListUsersQuery,
  GetListUsersQueryVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  GluPayload,
  Maybe,
} from "../../api/types";
import { GETLISTUSERS, DELETEUSER } from "../../api";
import { format } from "date-fns";
import UpdateClientPassword from "../UpdateClientPassword/UpdateClientPassword";

const Clients: FC = () => {
  const token = localStorage.getItem("token") || "";
  let history = useHistory();
  const intl = useIntl();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [deleteAccount, setDeleteAccount] = React.useState<string>("");
  const [listUsers, setListUsers] = React.useState<GluPayload[]>([]);
  const [search, setSearch] = React.useState<Maybe<string> | undefined>(
    undefined
  );
  const [usersCount, setUsersCount] = React.useState<number>(1000);
  const [passModalOpen, setPassModalOpen] = React.useState<boolean>(false);
  const [selectedClient, setSelectedClient] = React.useState<GluPayload>({});

  const { data: clients, loading } = useQuery<
    GetListUsersQuery,
    GetListUsersQueryVariables
  >(GETLISTUSERS, {
    variables: {
      input: {
        token: token,
        languageCode: "EN",
        kind: "MANAGER",
        pageSize: rowsPerPage,
        pageNumber: page + 1,
        searchString: search,
      },
    },
    fetchPolicy : "network-only"
  });

  React.useEffect(() => {
    clients?.getListUsers?.list && setListUsers(clients?.getListUsers?.list);
    clients?.getListUsers?.count && setUsersCount(clients?.getListUsers?.count);
  }, [clients]);

  const [DeleteUser, { loading: loadingDelete }] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DELETEUSER, {
    variables: {
      input: { token: token, id: deleteAccount },
    },
    onCompleted: () => {
      handleClose();
    },
    refetchQueries: () => [
      {
        query: GETLISTUSERS,
        variables: {
          input: {
            token: token,
            languageCode: "EN",
            kind: "MANAGER",
            pageSize: rowsPerPage,
            pageNumber: 1,
          },
        },
      },
    ],
  });

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setRowsPerPage(parseInt(event.target.value, 10));
  }

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleDelete = () => {
    DeleteUser();
  };

  const handleFilter = () => {
    // let arrayListUsersFilter: GluPayload[] = [];
    // if (search && clients?.getListUsers?.list) {
    //   arrayListUsersFilter = clients?.getListUsers?.list?.filter((item) => {
    //     if (
    //       item?.company?.fiscal?.corporateName?.includes(search) ||
    //       item?.email?.includes(search) ||
    //       item?.informations?.firstName?.includes(search) ||
    //       item?.informations?.lastName?.includes(search) ||
    //       item?.company?.address?.roadNames?.[0]?.value?.includes(search) ||
    //       item?.contact?.phone?.number?.includes(search)
    //     ) {
    //       return item;
    //     }
    //   });
    //   setListUsers(arrayListUsersFilter);
    // } else
    clients?.getListUsers?.list && setListUsers(clients?.getListUsers?.list);
  };

  const formatDate = (date: Maybe<string> | undefined) => {
    const unformattedDate = date ? new Date(date) : null;
    if (!unformattedDate) return "--/--/--";
    return format(unformattedDate, "MM/dd/yyyy");
  };
  return (
    <Wrapper>
      <Modal
        open={openModalDelete}
        title="Supprimer produit"
        message="vou voulez supprimer ce compte"
        handleClose={handleClose}
        handleContent={handleDelete}
        action="delete"
        loading={loadingDelete}
      />
      <div className="topbar">
        <Note>
          <FontAwesomeIcon icon={faInfoCircle} size="2x" color="#AFB1C6" />
          <span className="info">
            {intl.formatMessage({ id: "listOfClients.info1" })}
            <br />
            {intl.formatMessage({ id: "listOfClients.info2" })}
          </span>
        </Note>
        <Button
          style={{ color: "#FFFFFF" }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => {
            history.push("/new_client");
          }}
        >
          <FontAwesomeIcon icon={faPlus} color="#ffffff" />
          <span className="btn-description">
            {intl.formatMessage({ id: "listOfClients.add" })}
          </span>
        </Button>
      </div>
      <Search>
        <Input
          id="search"
          name="search"
          value={search || ""}
          onChange={(e) => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleFilter();
          }}
          fullWidth
          placeholder={intl.formatMessage({
            id: "listOfClients.placehoderSearch",
          })}
        />
        <Button
          style={{ color: "#FFFFFF", height: 44, borderRadius: "0  4px 4px 0" }}
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleFilter}
        >
          <FontAwesomeIcon icon={faSearch} color="#ffffff" />
        </Button>
      </Search>

      <Box width={1}>
        {loading && (
          <LinearProgress style={{ borderRadius: "10px 10px 0 0" }} />
        )}

        <Table className="clients-list">
          <TableHead className="table-head">
            <TableRow>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.socialReason" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.domain" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.email" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.firstName" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.lastName" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.address" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.phoneNumber" })}
              </TableCell>
              <TableCell component="th" align="left" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.createdAt" })}
              </TableCell>
              <TableCell component="th" align="center" className="table-cell">
                {intl.formatMessage({ id: "listOfClients.actions" })}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listUsers &&
              listUsers
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((client, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client.company?.fiscal?.corporateName}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client?.company?.domain}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client?.email}
                      </TableCell>

                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client?.informations?.firstName}
                      </TableCell>

                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client?.informations?.lastName}
                      </TableCell>

                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client.company?.address?.roadNames?.[0]?.value}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {client.contact?.phone?.number}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="left"
                        className="table-cell"
                      >
                        {formatDate(client?.createdAt)}
                      </TableCell>
                      <TableCell
                        component="td"
                        align="center"
                        className="table-cell"
                      >
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{
                              color: "#FFFFFF",
                              marginRight: "15px",
                              height: 35,
                            }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() =>
                              history.push({
                                pathname: "/update_client",
                                state: client,
                              })
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} color="#ffffff" />
                          </Button>
                          <Button
                            style={{
                              color: "#FFFFFF",
                              marginRight: "15px",
                              height: 35,
                            }}
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => {
                              setSelectedClient(client);
                              setPassModalOpen(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faKey} color="#ffffff" />
                          </Button>
                          <Button
                            style={{
                              color: "#F67070",
                              background: "#FCF1F1",
                              height: 35,
                            }}
                            variant="contained"
                            disableElevation
                            onClick={() => {
                              setOpenModalDelete(true);
                              setDeleteAccount(client.id || "");
                            }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
          <TableFooter>
            <TableRow>
              {listUsers && (
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={8}
                  count={usersCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  labelRowsPerPage={intl.formatMessage({
                    id: "listOfClients.lignesPerPage",
                  })}
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from}-${to} ${intl.formatMessage({
                      id: "listOfClients.of",
                    })} ${count}`
                  }
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              )}
            </TableRow>
          </TableFooter>
        </Table>
        <Dialog open={passModalOpen}>
          <DialogContent>
            <Note>
              {intl.formatMessage({
                id: "formClient.changePass",
              })}
            </Note>
            <UpdateClientPassword
              dataClient={selectedClient}
              onClose={() => setPassModalOpen(false)}
            />
          </DialogContent>
        </Dialog>
      </Box>
    </Wrapper>
  );
};

const Note = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-left: 15px;
    color: #afb1c6;
    font-weight: 100;
    font-style: italic;
  }
`;

const Wrapper = styled.div`
  padding: 10px 20px 0 0;
  min-width: 900px;
  .topbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .info {
      margin-left: 15px;
      color: #afb1c6;
      font-weight: 100;
      font-style: italic;
      font-size: 13px;
    }
    .btn-description {
      margin-left: 12px;
      font-size: 16px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  h2 {
    margin: 15px 0;
  }
  .table {
    background: #ffffff;
    border-radius: 5px;
    padding: 20px;
    box-sizing: border-box;
    overflow-x: auto;
    .table-head {
      background: #fafbff;
    }
    .table-row:hover {
      background: #fafbff;
    }
    .table-cell {
      border-bottom: 0.5px solid #f1f2fb;
    }
    .btn-action {
      margin-top: 4px;
      margin-left: 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      text-transform: lowercase;
      ::first-letter {
        text-transform: uppercase;
      }
    }
  }
  .btn-action {
    padding: 1px 0;
    margin-top: 4px;
    margin-left: 4px;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    text-transform: lowercase;
    ::first-letter {
      text-transform: uppercase;
    }
  }
  .clients-list tbody > tr:last-child > td {
    border-bottom: 0;
  }
  .MuiButton-root {
    min-width: 35px;
  }
`;

const Search = styled.div`
  display: flex;
  .mainInput {
    padding: 11px 12px 10px 12px;
    border-radius: 5px 0 0 5px;
    &:focus {
      border: 2px solid #00004e;
      border-radius: 5px 0 0 5px;
    }
  }
`;

export default Clients;
