import React, { useEffect } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export interface DialogProps {
  open: boolean;
  title: string;
  children?: React.ReactNode;
  handleClose: () => void;
  handleContent: () => void;
  message?: string;
  action: "delete" | "add" | "update";
  loading?: boolean;
}

const Modal: React.FC<DialogProps> = props => {
  const { open, title, action, message, children, loading } = props;

  const [Open, setOpen] = React.useState<boolean>(false);

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const handleModalContent = () => {
    props.handleContent();
  };

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={Open}
      onClose={handleClose}
    >
      <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
      <Wrapper className="modal-content">
        {children}
        {<span className="modal-message">{message}</span>}
        <div className="modal-action-btns">
          {action === "delete" ? (
            <Button
              variant="contained"
              disableElevation
              onClick={handleModalContent}
              style={{
                position: "relative",
                backgroundColor: loading ? "rgba(0, 0, 0, 0.12)" : "#F67070",
                color: loading ? "rgba(0, 0, 0, 0.26)" : "#ffffff"
              }}
              disabled={loading}
            >
              Supprimer
              {loading && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleModalContent}
              disabled={loading}
              // disabled
              style={{ position: "relative" }}
            >
              {action === "add" ? "Ajouter" : "Modifier"}
              {loading && (
                <CircularProgress size={24} style={{ position: "absolute" }} />
              )}
            </Button>
          )}
          <Button
            variant="contained"
            color="default"
            disableElevation
            onClick={handleClose}
          >
            Annuler
          </Button>
        </div>
      </Wrapper>
    </Dialog>
  );
};

const Wrapper = styled.div`
  .MuiFilledInput-root {
    border-radius: 5px;
    border: 2px solid #f1f2fb;
    background-color: #ffffff;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
  }
  .modal-message {
    color: #757575;
    display: block;
  }
  padding: 0 24px;
  .modal-action-btns {
    padding: 24px 0px 24px;
    min-width: 380px;
    display: flex;
    flex-direction: row-reverse;
    button:first-child {
      margin-left: 24px;
    }
  }
`;

export default Modal;
