import React from "react";
import styled from "styled-components";

type BoxType = {
  children: any;
  p?: number;
  m?: number;
  height?: number;
  width?: number;
  display?: string;
  justifyContent?: string;
  alignItems?: string;
  position?: string;
  selected?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  overflow?: string;
  className?: string;
};

const Box: React.FC<BoxType> = ({ children, className, ...restProps }) => {
  return (
    <Wrapper className={className} {...restProps}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<BoxType>`
  background-color: #ffffff;
  position: ${(props) => (props.position ? props.position : "static")};
  padding: ${(props) => (props.p ? props.p + "px" : "unset")};
  margin: ${(props) => (props.m ? props.m + "px" : "unset")};
  width: fit-content;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth + "px" : "initial")};
  max-height: ${(props) =>
    props.maxHeight ? props.maxHeight + "px" : "initial"};
  width: ${(props) =>
    props.width && (props.width === 1 ? "100%" : props.width + "px")};
  height: ${(props) => (props.height ? props.height + "px" : "fit-content")};
  display: ${(props) => (props.display ? props.display : "block")};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "unset")};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "unset"};
  border: ${(props) =>
    props.selected ? "2px solid rgba(116, 171, 255, 1)" : "2px solid #f3f4fa"};
  box-sizing: border-box;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  overflow: ${(props) => (props.overflow ? props.overflow : "unset")};
`;

export default Box;
