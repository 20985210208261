import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { IntlProvider } from "react-intl";
import { useQuery } from "@apollo/react-hooks";
//import from local
import enMessages from "./language/en";
import frMessages from "./language/fr";
import { Login, ResetPassword, Layout } from "./modules";
import { PublicRoute, PrivateRoute } from "./commons";
//import api
import { GETMYACCOUNTSETTINGS } from "./api";
import {
  GetMyAccountSettingsQuery,
  GetMyAccountSettingsQueryVariables,
} from "./api/types";

const theme = createMuiTheme({
  palette: {
    primary: { main: "#00004E", contrastText: "#fff" },
    secondary: { main: "#F24976" },
    text: { primary: "#444444", secondary: "#757575" },
    error: { main: "#F67070", contrastText: "#fff" },
    warning: { main: "#FCF1F1", contrastText: "#F67070" },
    success: { main: "#4CD964", contrastText: "#fff" },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const messages = {
  en: enMessages,
  fr: frMessages,
};

const App: React.FC = () => {
  const [token, setToken] = React.useState(localStorage.getItem("token") || "");

  //query get my account setting
  const { data: dataAccount } = useQuery<
    GetMyAccountSettingsQuery,
    GetMyAccountSettingsQueryVariables
  >(GETMYACCOUNTSETTINGS, {
    variables: {
      token: token,
    },
  });

  const language =
    (dataAccount?.getMyAccountSettings?.accountSettings?.language?.toLowerCase() as keyof typeof messages) ||
    "en";

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <Router>
            <Switch>
              <PublicRoute
                path={"/login"}
                component={() => (
                  <Login getToken={(token) => setToken(token)} />
                )}
                exact
              />
              <PublicRoute
                path={"/reset_password"}
                component={ResetPassword}
                exact
              />
              <PrivateRoute path={"/"} component={Layout} />
            </Switch>
          </Router>
        </MuiThemeProvider>
      </div>
    </IntlProvider>
  );
};

export default App;
