import React from "react";
import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker, BaseDatePickerProps } from "@material-ui/pickers";

type InputProps = BaseDatePickerProps & {
  label: string;
  msgError?: string;
  fullWidth?: boolean;
  value: string;
  onChange: any;
  format?: string;
  required?: boolean;
};

const DatePicker: React.FC<InputProps> = ({
  label,
  msgError,
  fullWidth,
  value,
  onChange,
  format,
  required,
  ...restProps
}) => {
  return (
    <FormControlStyled
      variant="filled"
      error={msgError !== "" && msgError !== undefined}
      fullWidth={fullWidth}
    >
      <InputLabel htmlFor="component-filled" shrink required={required}>
        {label}
      </InputLabel>

      <KeyboardDatePicker
        value={value}
        onChange={onChange}
        format={format}
        error={msgError !== "" && msgError !== undefined}
        {...restProps}
      />
      {msgError && <FormHelperText> {msgError} </FormHelperText>}
    </FormControlStyled>
  );
};

const FormControlStyled = styled(FormControl)`
  .MuiInputBase-root {
    overflow: "hidden";
    background-color: #ffffff;
    padding: 21px 12px 3px;
    border: 2px solid #f1f2ff;
    border-radius: 5px;
    ::before {
      content: none;
    }
    ::after {
      content: none;
    }
    &:focus-within {
      border: 2px solid #00004e;
    }
  }
  .MuiInputBase-root.Mui-error {
    border: 2px solid #f67070;
  }
  .MuiIconButton-root {
    top: -8px;
    right: -10px;
  }
`;

export default DatePicker;
