import { LoginInfo } from "./login";
import {
  emptyLogin,
  emptyPassword,
  invalidLogin,
  invalidPassword
} from "../../utils";

type Error = LoginInfo;

export const loginValidation = (values: LoginInfo): Error => {
  let errors: Error = {
    username: "",
    password: ""
  };

  Object.keys(values).forEach((key: string) => {
    switch (key) {
      case "username":
        if (!values.username) {
          errors.username = emptyLogin;
        } else if (
          !values.username.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          errors.username = invalidLogin;
        } else {
          delete errors.username;
        }
        break;
      case "password":
        if (!values.password) {
          errors.password = emptyPassword;
        } else if (!(values.password.length > 5)) {
          errors.password = invalidPassword;
        } else {
          delete errors.password;
        }
        break;
      default:
        break;
    }
  });
  return errors;
};
