import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { Box, Input } from "../../commons";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
//import apollo
import { useMutation } from "@apollo/react-hooks";
//import api signIn
import { SIGNIN, GETMYACCOUNTSETTINGS } from "../../api";
import { SignInMutation, SignInMutationVariables } from "../../api/types";
//validation form login
import { loginValidation } from "./loginValidation";

import {
  userNotFound,
  invalidPasswordGraphql,
  microserviceUnvailable,
} from "../../utils";

export type LoginInfo = {
  username: string;
  password: string;
};

type LoginProps = {
  getToken: (token: string) => void;
};

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const initialState: LoginInfo = {
  password: "",
  username: "",
};

const Login: React.FC<LoginProps> = ({ getToken }) => {
  let history = useHistory();

  const [rememberMe, setRememberMe] = React.useState(true);
  const [loginInfo, setLoginInfo] = React.useState<LoginInfo>(initialState);
  const [loginError, setLoginError] = React.useState<LoginInfo>(initialState);
  const [open, setOpen] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState("");

  //Mutation signIn
  const [signIn] = useMutation<SignInMutation, SignInMutationVariables>(
    SIGNIN,
    {
      onCompleted: ({ signIn }) => {
        if (signIn.kind === "Administrator") {
          localStorage.setItem("token", signIn.token);
          getToken(signIn.token);
          history.push("/");
        } else {
          setErrorMessages("Compte invalid");
          setOpen(true);
        }
      },
      onError: (error) => {
        if (
          error.graphQLErrors[0].message.replace(/["]/g, "") ===
          "user_not_found"
        ) {
          setErrorMessages(userNotFound);
        } else if (
          error.graphQLErrors[0].message.replace(/["]/g, "") ===
          "invalid_password"
        ) {
          setErrorMessages(invalidPasswordGraphql);
        } else setErrorMessages(microserviceUnvailable);
        setOpen(true);
      },
      variables: {
        login: loginInfo.username,
        password: loginInfo.password,
      },
      refetchQueries: ({ data }) => {
        return [
          {
            query: GETMYACCOUNTSETTINGS,
            variables: { token: data?.signIn?.token },
          },
        ];
      },
    }
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(event.target.checked);
  };

  const handleLoginInfo = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoginInfo({ ...loginInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validation = loginValidation(loginInfo);
    if (Object.entries(validation).length === 0) {
      setLoginError(validation);
      signIn();
    } else {
      setLoginError(validation);
    }
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Wrapper>
      <Box p={24} width={400}>
        <form data-testid="login-form" onSubmit={handleSubmit}>
          <Input
            id="username"
            data-testid="username"
            label={"username"}
            name="username"
            onChange={handleLoginInfo}
            msgError={loginError.username}
            fullWidth
          />
          <Input
            id="password"
            data-testid="password"
            name="password"
            label={"password"}
            onChange={handleLoginInfo}
            msgError={loginError.password}
            type="password"
            fullWidth
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "14px",
            }}
          >
            <span>
              <Checkbox
                data-testid="remember"
                checked={rememberMe}
                color="primary"
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              <label style={{ fontWeight: 500, fontSize: "13px" }}>
                {"remember"}
              </label>
            </span>
            <Link
              style={{
                cursor: "pointer",
                color: "#C4C4C4",
                fontSize: "14px",
              }}
              onClick={() => {
                history.push("/reset_password");
              }}
            >
              {"forgetPassword"}
            </Link>
          </div>
          <div style={{ display: "flex" }}>
            <Button
              data-testid="submit"
              type="submit"
              style={{ color: "#FFFFFF", margin: "30px auto 16px" }}
              variant="contained"
              color="primary"
              disableElevation
            >
              {"login"}
            </Button>
          </div>
        </form>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {errorMessages}
        </Alert>
      </Snackbar>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export default Login;
