export default {
  //Title in Layout & MainLayout

  "listOfClient.Title": "Liste des clients",
  "addNewCustomer.Title": "Ajouter nouveau client",
  "editCustomer.Title": "Modifier client",
  "signOut.Title": "Déconnexion",
  "setting.Title": "Paramètre",
  "account.Title": "Compte",

  //Description info
  "listOfClients.info1":
    "Gérez vos clients, ajoutez-en de nouveaux à votre liste ou modifiez",
  "listOfClients.info2": "et supprimer les clients existants!",

  //Page list of clients

  "listOfClients.add": "Ajouter un client",
  "listOfClients.socialReason": "Raison sociale",
  "listOfClients.domain": "Domaine",
  "listOfClients.email": "Email",
  "listOfClients.firstName": "Prénom",
  "listOfClients.lastName": "Nom",
  "listOfClients.address": "Adresse",
  "listOfClients.phoneNumber": "Numéro de téléphone",
  "listOfClients.createdAt": "Créé le",
  "listOfClients.actions": "Actions",
  "listOfClients.edit": "Editer",
  "listOfClients.delete": "Supprimer",
  "listOfClients.lignesPerPage": "Lignes par page",
  "listOfClients.of": "Sur",
  "listOfClients.placehoderSearch": "Tapez pour rechercher...",

  //Page update & create client
  "formClient.save": "Enregistrer",
  "formClient.companyInformation": "Informations de l'entreprise",
  "formClient.DomainName": "Nom de domaine",
  "formClient.alphanumericCharacters": "Alphanumeric characters",
  "formClient.noSpecialCharacters": "Pas de caractères spéciales",
  "formClient.domainOfActivity": "Domaine d'activité",
  "formClient.taxDetails": "Détails fiscale",
  "formClient.socialReason": "Raison sociale",
  "formClient.matriculation": "Immatriculation",
  "formClient.tvaCode": "Code TVA",
  "formClient.address": "Adresse",
  "formClient.country": "Pays",
  "formClient.city": "Ville",
  "formClient.postalCode": "Code postal",
  "formClient.telephoneNumber": "Telephone number",
  "formClient.countryCode": "Code pays",
  "formClient.phone": "Téléphone",
  "formClient.personalInformation": "Informations personnel",
  "formClient.firstContact": "Premier contact",
  "formClient.firstName": "Prénom",
  "formClient.lastName": "Nom",
  "formClient.generalSettings": "Paramètres générales",
  "formClient.generalAccountSettings": "Paramètres généraux du compte",
  "formClient.primaryLanguage": "langue principale",
  "formClient.currency": "Devise",
  "formClient.socialMedia": "Réseaux sociaux",
  "formClient.Website": "Site web",
  "formClient.selectSocialMedia": "Selectionner le réseaux social",
  "formClient.link": "Lien",
  "formClient.account": "Compte",
  "formClient.userType": "Type d'utilisateur",
  "formClient.user": "Utilisateur",
  "formClient.email": "Email",
  "formClient.password": "Mot de passe",
  "formClient.confirmPassword": "Confirmez le mot de passe",
  "formClient.privileges": "Privilèges",
  "formClient.ParametersAndTypeOfPack": "Paramètres et type du pack",
  "formClient.multiSites": "Multi sites",
  "formClient.orderingExperience": "Expérience de commande",
  "formClient.numberOfMenusAllowed": "Nombre de menu autorisé",
  "formClient.numberOfSlidesAllowed": "Nombre de dispositive autorisé",
  "formClient.packes": "Packs",
  "formClient.accountStatus": "Statut du compte",
  "formClient.expirationDate": "Date d'expiration",
  "formClient.cancel": "Annuler",
  "formClient.changePass" : "Modifier le mot de passe",

  //page settings
  "Settings.title": "Paramètres",
  "Settings.label": "Paramètre de la langue",

  //alert
  "domainAlreadyExists.Alert": "Le domaine existe déjà",
  "emailAlreadyExists.Alert": "l'email existe déjà",
  "checkTheRequiredFields.Alert": "Vérifiez les champs obligatoires",
};
