import React, { FC } from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

type BarType = {
  day: string;
  value: number;
};

type WrapperType = {
  value: number;
  day: string;
};

const Bar: FC<BarType> = ({ day, value }) => {
  return (
    <Wrapper value={value} day={day}>
      <label className="progress up">{value}</label>
      <Tooltip title={day} placement="bottom">
        <div className="bar">
          <label className="day">{day.substring(0, 3)}</label>
        </div>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div<WrapperType>`
  .bar {
    width: 40px;
    height: ${props => props.value}px;
    min-height: 18px;
    background: #00004e;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: all 0.6s cubic-bezier(0.5, 0.6, 0.4, 1);
    animation: ${props => props.day};
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
    .day {
      font-size: 12px;
      color: #ffffff;
    }
  }
  label.progress {
    display: block;
    text-align: center;
  }
  .up {
    color: #4cd964;
  }
  .down {
    color: #f67070;
  }
`;

export default Bar;
