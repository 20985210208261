export const getURLSocialMedia = (kind?: string | null) => {
  switch (kind) {
    case "Facebook":
      return "https://www.facebook.com/";
    case "Twitter":
      return "https://www.twitter.com/";
    case "YouTube":
      return "https://www.youtube.com/";
    case "WhatsApp":
      return "https://www.whatsapp.com/";
    case "Instagram":
      return "https://www.instagram.com/";
    case "Reddit":
      return "https://www.reddit.com/";
    case "Tumblr":
      return "https://www.tumblr.com/";
    case "Pinterest":
      return "https://www.pinterest.com/";
    case "Snapchat":
      return "https://www.snapchat.com/";
    case "LinkedIn":
      return "https://www.linkedin.com/";
    case "Google+":
      return "https://plus.google.com/";
    case "Periscope":
      return "https://www.pscp.tv/";
    default:
      return "";
  }
};
